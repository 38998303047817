import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Logger from '../../../../Utils/Logger';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  CART_CLEAR_ENDPOINT,
  CART_LIST_ENDPOINT,
  CART_REMOVE_ENDPOINT,
  CHECKOUT_PROCEED_ENDPOINT,
} from '../../../../Utils/Endpoints';
import Empty from '../Components/Empty';
import Toast from '../../../../Utils/Toast';
import { getObject, saveObject } from '../../../../Utils/LocalStorage';

const Cart = () => {
  const [cartList, setCartList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isClearLoading, setIsClearLoading] = useState(false);
  const [isProceedLoading, setIsProceedLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(CART_LIST_ENDPOINT);
      if (response.data.code === 200) {
        if (response?.data?.data?.cart !== 'Your cart is empty.') {
          setCartList(response.data.data);
        } else {
          setCartList({});
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProceedToCheckout = async (e) => {
    e.preventDefault();
    setIsProceedLoading(true);
    try {
      const orderData = getObject('proceedCheckoutData');
      let requestObject = {
        notes: '',
        order_id: orderData ? orderData?.id : null,
      };
      const response = await axiosInstance.post(
        CHECKOUT_PROCEED_ENDPOINT,
        requestObject
      );
      if (response.data.code === 200) {
        saveObject('proceedCheckoutData', response.data.data);
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
        navigate('/check-out');
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsProceedLoading(false);
    }
  };

  const handleRemove = async (e, key) => {
    e.preventDefault();
    try {
      setIsRemoveLoading(true);
      const requestObject = {
        key: key,
      };
      const response = await axiosInstance.post(
        CART_REMOVE_ENDPOINT,
        requestObject
      );
      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      await fetchCart();
      setIsRemoveLoading(false);
    }
  };

  const handleClearCart = async (e) => {
    e.preventDefault();
    try {
      setIsClearLoading(true);
      const response = await axiosInstance.delete(CART_CLEAR_ENDPOINT);
      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      await fetchCart();
      setIsClearLoading(false);
    }
  };

  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* html new add start */}

            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <h2 className="text-white">Cart</h2>

                    {!isLoading &&
                      cartList &&
                      Object.keys(cartList).length > 0 && (
                        <div className="edgtf-grid-row">
                          <div className="text-align-right mb-2">
                            <button
                              className="btn-danger"
                              disabled={isClearLoading}
                              onClick={handleClearCart}
                            >
                              {isClearLoading ? 'Loading...' : 'Clear Cart'}
                            </button>
                          </div>
                          <div className="edgtf-page-content-holder edgtf-grid-col-12">
                            <div className="woocommerce">
                              <div className="woocommerce-notices-wrapper"></div>
                              <form
                                className="woocommerce-cart-form"
                                action="https://offbeat.qodeinteractive.com/cart/"
                                method="post"
                              >
                                <table
                                  className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                                  cellSpacing="0"
                                >
                                  <thead>
                                    <tr>
                                      <th className="product-remove px-2">
                                        <span className="screen-reader-text">
                                          Remove item
                                        </span>
                                      </th>
                                      <th className="product-thumbnail px-2">
                                        <span className="screen-reader-text">
                                          Thumbnail image
                                        </span>
                                      </th>
                                      <th className="product-name px-2">
                                        Product
                                      </th>
                                      <th className="product-price px-2">
                                        Price
                                      </th>
                                      <th className="product-quantity text-center px-2">
                                        Quantity
                                      </th>
                                      <th className="product-subtotal text-end pe-2 ">
                                        Subtotal
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.entries(cartList?.cart?.items).map(
                                      ([
                                        key,
                                        { item_totals, model, quantity },
                                      ]) => (
                                        <tr
                                          className="woocommerce-cart-form__cart-item cart_item"
                                          key={key}
                                        >
                                          <td className="product-remove">
                                            <button
                                              className="cross-icon"
                                              onClick={(e) =>
                                                handleRemove(e, key)
                                              }
                                              disabled={isRemoveLoading}
                                            >
                                              <img
                                                src="../images/cross.png"
                                                alt=""
                                              />
                                            </button>
                                          </td>

                                          <td className="product-thumbnail">
                                            {/* <Link to="https://offbeat.qodeinteractive.com/product/blurrybus/">
                                            <img
                                              fetchPriority="high"
                                              decoding="async"
                                              width="300"
                                              height="300"
                                              src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img-300x300.jpg"
                                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                              alt="l"
                                              srcSet="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img-300x300.jpg 300w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img-116x116.jpg 116w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img-768x768.jpg 768w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img-550x550.jpg 550w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img.jpg 800w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img-100x100.jpg 100w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/shop-12-img-150x150.jpg 150w"
                                              sizes="(max-width: 300px) 100vw, 300px"
                                            />
                                          </Link> */}
                                          </td>

                                          <td
                                            className="product-name tt-t"
                                            data-title="Product"
                                          >
                                            <Link>{model?.name}</Link>
                                          </td>

                                          <td
                                            className="product-price"
                                            data-title="Price"
                                          >
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  {model?.priceFormat}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>

                                          <td
                                            className="product-quantity text-center"
                                            data-title="Quantity"
                                          >
                                            <div className="edgtf-quantity-buttons quantity">
                                              <label
                                                className="screen-reader-text"
                                                htmlFor="quantity_6721d5cb47a3c"
                                              >
                                                {model?.name}
                                              </label>

                                              <span className="text-white">
                                                <bdi>{quantity}</bdi>
                                              </span>
                                            </div>
                                          </td>

                                          <td
                                            className="product-subtotal text-end pe-2"
                                            data-title="Subtotal"
                                          >
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol text-white">
                                                  {
                                                    item_totals?.base_total_format
                                                  }
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    )}

                                    <tr className="d-none">
                                      <td colSpan="6" className="actions">
                                        <div className="coupon">
                                          <label
                                            htmlFor="coupon_code"
                                            className="screen-reader-text"
                                          >
                                            Coupon:
                                          </label>{' '}
                                          <input
                                            type="text"
                                            name="coupon_code "
                                            className="input-text text-white"
                                            id="coupon_code"
                                            value=""
                                            placeholder="Promo Code"
                                          />
                                          <button
                                            type="submit"
                                            className="button"
                                            name="apply_coupon"
                                            value="Apply coupon"
                                          >
                                            Apply coupon
                                          </button>
                                        </div>
                                        {/* <button
                                   type="submit"
                                   className="button"
                                   name="update_cart"
                                   value="Update cart"
                                   disabled=""
                                 >
                                   Update cart
                                 </button> */}
                                        <input
                                          type="hidden"
                                          id="woocommerce-cart-nonce"
                                          name="woocommerce-cart-nonce"
                                          value="a93b735570"
                                        />
                                        <input
                                          type="hidden"
                                          name="_wp_http_referer"
                                          value="/cart/"
                                        />{' '}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>

                              <div className="cart-collaterals">
                                <div className="cart_totals calculated_shipping ">
                                  <h2 className="text-white">Cart totals</h2>

                                  <table
                                    cellSpacing="0"
                                    className="shop_table shop_table_responsive"
                                  >
                                    <tbody>
                                      <tr className="cart-subtotal">
                                        <th>Subtotal</th>
                                        <td data-title="Subtotal">
                                          <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                              <span className="woocommerce-Price-currencySymbol">
                                                {
                                                  cartList?.cart?.totals
                                                    ?.base_total_format
                                                }
                                              </span>
                                            </bdi>
                                          </span>
                                        </td>
                                      </tr>

                                      <tr className="woocommerce-shipping-totals shipping d-none">
                                        <th>Shipping</th>
                                        <td data-title="Shipping">
                                          <ul
                                            id="shipping_method"
                                            className="woocommerce-shipping-methods"
                                          >
                                            <li>
                                              <input
                                                type="hidden"
                                                name="shipping_method[0]"
                                                data-index="0"
                                                id="shipping_method_0_free_shipping2"
                                                value="free_shipping:2"
                                                className="shipping_method"
                                              />
                                              <label htmlFor="shipping_method_0_free_shipping2">
                                                Free shipping
                                              </label>{' '}
                                            </li>
                                          </ul>
                                          <p className="woocommerce-shipping-destination">
                                            Shipping to{' '}
                                            <strong>
                                              Gatlinburg, TN 37738, Gatlinburg,
                                              TN 37738, Gatlinburg, TN 37738
                                            </strong>
                                            .{' '}
                                          </p>

                                          <form
                                            className="woocommerce-shipping-calculator"
                                            action="https://offbeat.qodeinteractive.com/cart/"
                                            method="post"
                                          >
                                            <Link
                                              to="#"
                                              className="shipping-calculator-button"
                                            >
                                              Change address
                                            </Link>
                                            <section
                                              className="shipping-calculator-form"
                                              style={{ display: 'none' }}
                                            >
                                              <p
                                                className="form-row form-row-wide"
                                                id="calc_shipping_country_field"
                                              >
                                                <label
                                                  htmlFor="calc_shipping_country"
                                                  className="screen-reader-text"
                                                >
                                                  Country / region:
                                                </label>

                                                <span
                                                  className="select2 select2-container select2-container--default"
                                                  dir="ltr"
                                                  data-select2-id="1"
                                                  style={{ width: 'auto' }}
                                                >
                                                  <span className="selection">
                                                    <span
                                                      className="select2-selection select2-selection--single"
                                                      role="combobox"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                      tabIndex="0"
                                                      aria-disabled="false"
                                                      aria-labelledby="select2-calc_shipping_country-container"
                                                    >
                                                      <span
                                                        className="select2-selection__rendered"
                                                        id="select2-calc_shipping_country-container"
                                                        role="textbox"
                                                        aria-readonly="true"
                                                        title="United States (US)"
                                                      >
                                                        United States (US)
                                                      </span>
                                                      <span
                                                        className="select2-selection__arrow"
                                                        role="presentation"
                                                      >
                                                        <b role="presentation"></b>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <span
                                                    className="dropdown-wrapper"
                                                    aria-hidden="true"
                                                  ></span>
                                                </span>
                                              </p>
                                              <p
                                                className="form-row validate-required form-row-wide address-field"
                                                id="calc_shipping_state_field"
                                              >
                                                <span>
                                                  <label
                                                    htmlFor="calc_shipping_state"
                                                    className="screen-reader-text"
                                                  >
                                                    State&nbsp;
                                                    <abbr
                                                      className="required"
                                                      title="required"
                                                    >
                                                      *
                                                    </abbr>
                                                  </label>

                                                  <span
                                                    className="select2 select2-container select2-container--default"
                                                    dir="ltr"
                                                    data-select2-id="3"
                                                    style={{
                                                      width: 'auto',
                                                    }}
                                                  >
                                                    <span className="selection">
                                                      <span
                                                        className="select2-selection select2-selection--single"
                                                        role="combobox"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        tabIndex="0"
                                                        aria-disabled="false"
                                                        aria-labelledby="select2-calc_shipping_state-container"
                                                      >
                                                        <span
                                                          className="select2-selection__rendered"
                                                          id="select2-calc_shipping_state-container"
                                                          role="textbox"
                                                          aria-readonly="true"
                                                          title="Tennessee"
                                                        >
                                                          Tennessee
                                                        </span>
                                                        <span
                                                          className="select2-selection__arrow"
                                                          role="presentation"
                                                        >
                                                          <b role="presentation"></b>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span
                                                      className="dropdown-wrapper"
                                                      aria-hidden="true"
                                                    ></span>
                                                  </span>
                                                </span>
                                              </p>
                                              <p
                                                className="form-row validate-required form-row-wide address-field"
                                                id="calc_shipping_city_field"
                                              >
                                                <label
                                                  htmlFor="calc_shipping_city"
                                                  className="screen-reader-text"
                                                >
                                                  Town / City&nbsp;
                                                  <abbr
                                                    className="required"
                                                    title="required"
                                                  >
                                                    *
                                                  </abbr>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="input-text"
                                                  value="Gatlinburg"
                                                  placeholder="City"
                                                  name="calc_shipping_city"
                                                  id="calc_shipping_city"
                                                />
                                              </p>
                                              <p
                                                className="form-row validate-required form-row-wide address-field"
                                                id="calc_shipping_postcode_field"
                                              >
                                                <label
                                                  htmlFor="calc_shipping_postcode"
                                                  className="screen-reader-text"
                                                >
                                                  ZIP Code&nbsp;
                                                  <abbr
                                                    className="required"
                                                    title="required"
                                                  >
                                                    *
                                                  </abbr>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="input-text"
                                                  value="37738"
                                                  placeholder="Postcode / ZIP"
                                                  name="calc_shipping_postcode"
                                                  id="calc_shipping_postcode"
                                                />
                                              </p>
                                              <p>
                                                <button
                                                  type="submit"
                                                  name="calc_shipping"
                                                  value="1"
                                                  className="button"
                                                >
                                                  Update
                                                </button>
                                              </p>
                                              <input
                                                type="hidden"
                                                id="woocommerce-shipping-calculator-nonce"
                                                name="woocommerce-shipping-calculator-nonce"
                                                value="eff7a5d7d7"
                                              />
                                              <input
                                                type="hidden"
                                                name="_wp_http_referer"
                                                value="/cart/"
                                              />{' '}
                                            </section>
                                          </form>
                                        </td>
                                      </tr>

                                      <tr className="order-total">
                                        <th>Total</th>
                                        <td data-title="Total">
                                          <strong>
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  {
                                                    cartList?.cart?.totals
                                                      ?.total_fee_format
                                                  }
                                                </span>
                                              </bdi>
                                            </span>
                                          </strong>{' '}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div className="wc-proceed-to-checkout">
                                    <Link
                                      type="button"
                                      disabled={isProceedLoading}
                                      onClick={handleProceedToCheckout}
                                      className="checkout-button button alt wc-forward"
                                    >
                                      {isProceedLoading
                                        ? 'Loading checkout...'
                                        : 'Proceed to checkout'}
                                    </Link>
                                  </div>

                                  {generalError && (
                                    <div className="error">{generalError}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {!isLoading &&
                      cartList &&
                      Object.keys(cartList)?.length === 0 && <Empty />}
                  </div>
                </div>
              </div>
            </div>
            {/* html new add end */}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
