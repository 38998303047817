import { useParams } from 'react-router-dom';
import { decrypt, splitString } from '../../../Utils/common';
import OrgEvent from './Events/OrgEvent';
import NotFound from '../../../Components/NotFound';
import EventPreview from './Events/EventPreview';

const Preview = () => {
  const { id } = useParams();
  const decryptData = decrypt(id);
  const data = splitString(decryptData);

  return (
    <div>
      {data?.type === 'ORG' && <OrgEvent orgId={data?.key} />}
      {data?.type === 'EVENT' && <EventPreview eventId={data?.key} />}
      {data?.type !== 'EVENT' && data?.type !== 'ORG' && <NotFound />}
    </div>
  );
};

export default Preview;
