import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axiosInstance from '../../../../Utils/axiosInstance';
import { FORGOT_ENDPOINT, RESET_ENDPOINT } from '../../../../Utils/Endpoints';

// Validation schema
const ForgotSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required.'),
});

const ResetSchema = Yup.object().shape({
  OTP: Yup.string().required('OTP is required.'),
  password: Yup.string().min(8).required('Password is required.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(8)
    .required('Confirm Password is required.'),
});

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [OTP, setOTP] = useState('');
  const [token, setToken] = useState('');
  const [succMsg, setSuccMsg] = useState('');

  const validate = () => {
    try {
      setGeneralError('');
      ForgotSchema.validateSync({ email }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };
  const validateResetSchema = () => {
    try {
      setGeneralError('');
      ResetSchema.validateSync(
        { password, confirmPassword, OTP },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmitOne = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(FORGOT_ENDPOINT, {
        email,
      });
      if (response.data.code === 200) {
        setToken(response?.data?.data?.confirmation_token);
        setSuccMsg(response.data.message);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitTwo = async (e) => {
    e.preventDefault();
    if (!validateResetSchema()) return;
    setGeneralError('');
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(RESET_ENDPOINT, {
        email,
        confirmation_token: token,
        code: OTP,
        confirm_password: confirmPassword,
        password,
      });

      if (response.data.code === 200) {
        setSuccMsg(response.data.message);
        setTimeout(() => {
          setSuccMsg('');
        }, 1000);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="wrapper">
        <div className="">
          <div className="row g-0 m-0">
            <div className="col-xl-6 col-lg-12">
              <div className="login-cover-wrapper">
                <div className="logo">
                  <img
                    src="images/logo-icon-2.png"
                    className="logo-icon"
                    alt="logo icon"
                  />
                  <h4 className="logo-text text-warning">PARTYPASS</h4>
                </div>
                <div className="card shadow-none">
                  {token === '' && (
                    <div className="card-body">
                      <div className="text-center">
                        <h4>Forgot Password</h4>
                        <p>Verify your account</p>
                      </div>
                      <form
                        className="form-body row g-3"
                        onSubmit={handleSubmitOne}
                      >
                        <div className="col-12">
                          <label htmlFor="inputEmail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors?.email && (
                            <div className="error">{errors.email}</div>
                          )}
                        </div>
                        <div className="col-12 col-lg-12">
                          {generalError && (
                            <div className="error">{generalError}</div>
                          )}
                          <div className="d-grid">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isLoading}
                            >
                              Recover Password
                            </button>
                          </div>
                        </div>
                        <div className="col-12 col-lg-12 text-center">
                          <p className="mb-0">
                            Back to <Link to="/login">Login</Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  )}
                  {token && (
                    <div className="card-body">
                      <div className="text-center">
                        <h4>Reset Password</h4>
                      </div>
                      {succMsg && (
                        <div className="col-12 text-success">{succMsg}</div>
                      )}
                      <form
                        className="form-body row g-3"
                        onSubmit={handleSubmitTwo}
                      >
                        <div className="col-12">
                          <label htmlFor="inputEmail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            value={email}
                            placeholder="Email"
                            disabled
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="inputOTP" className="form-label">
                            OTP
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputOTP"
                            value={OTP}
                            onChange={(e) => setOTP(e.target.value)}
                            placeholder="OTP"
                          />
                          {errors?.OTP && (
                            <div className="error">{errors.OTP}</div>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="inputPassword" className="form-label">
                            Password
                          </label>
                          <input
                            className="form-control"
                            id="inputPassword"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password*"
                          />
                          {errors.password && (
                            <div className="error">{errors.password}</div>
                          )}
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="inputConfirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            className="form-control"
                            id="inputConfirmPassword"
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password*"
                          />
                          {errors.confirmPassword && (
                            <div className="error">
                              {errors.confirmPassword}
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-lg-12">
                          {generalError && (
                            <div className="error">{generalError}</div>
                          )}
                          <div className="d-grid">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isLoading}
                            >
                              Change Password
                            </button>
                          </div>
                        </div>
                        <div className="col-12 col-lg-12 text-center">
                          <p className="mb-0">
                            Back to <Link to="/login">Login</Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="position-fixed top-0 h-100 d-xl-block d-none login-cover-img"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
