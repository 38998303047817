import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="edgtf-page-footer ">
        <div className="edgtf-footer-top-holder border-dis">
          <div className="edgtf-footer-top-inner edgtf-grid pb-3 pt-0 pt-md-5">
            <div className="edgtf-grid-row edgtf-footer-top-alignment-left">
              <div className="edgtf-column-content edgtf-grid-col-3 d-none">
                <div
                  id="text-7"
                  className="widget edgtf-footer-column-1 widget_text"
                >
                  <h5 className="edgtf-widget-title">Opening Hours</h5>
                  <div className="textwidget"></div>
                </div>
                <div className="widget edgtf-opening-hours-widget">
                  <div className="edgtf-opening-hours-holder">
                    <div className="edgtf-opening-hours-holder-inner">
                      <div
                        className="edgtf-opening-hours-day-holder"
                        style={{ color: '#ffffff' }}
                      >
                        <div className="edgtf-opening-hours-day">Monday</div>
                      </div>
                      <div className="edgtf-opening-hours-line"></div>
                      <div
                        className="edgtf-opening-hours-time"
                        style={{ color: '#f45f0b' }}
                      >
                        CLOSED
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget edgtf-opening-hours-widget">
                  <div className="edgtf-opening-hours-holder">
                    <div className="edgtf-opening-hours-holder-inner">
                      <div
                        className="edgtf-opening-hours-day-holder"
                        style={{ color: '#ffffff' }}
                      >
                        <div className="edgtf-opening-hours-day">Tuesday</div>
                      </div>
                      <div className="edgtf-opening-hours-line"></div>
                      <div
                        className="edgtf-opening-hours-time"
                        style={{ color: '#ffffff' }}
                      >
                        9:00 - 22:00
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget edgtf-opening-hours-widget">
                  <div className="edgtf-opening-hours-holder">
                    <div className="edgtf-opening-hours-holder-inner">
                      <div
                        className="edgtf-opening-hours-day-holder"
                        style={{ color: '#ffffff' }}
                      >
                        <div className="edgtf-opening-hours-day">Wednesday</div>
                      </div>
                      <div className="edgtf-opening-hours-line"></div>
                      <div
                        className="edgtf-opening-hours-time"
                        style={{ color: '#ffffff' }}
                      >
                        9:00 - 22:00
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget edgtf-opening-hours-widget">
                  <div className="edgtf-opening-hours-holder">
                    <div className="edgtf-opening-hours-holder-inner">
                      <div
                        className="edgtf-opening-hours-day-holder"
                        style={{ color: '#ffffff' }}
                      >
                        <div className="edgtf-opening-hours-day">Thursday</div>
                      </div>
                      <div className="edgtf-opening-hours-line"></div>
                      <div
                        className="edgtf-opening-hours-time"
                        style={{ color: '#ffffff' }}
                      >
                        9:00 - 22:00
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget edgtf-opening-hours-widget">
                  <div className="edgtf-opening-hours-holder">
                    <div className="edgtf-opening-hours-holder-inner">
                      <div
                        className="edgtf-opening-hours-day-holder"
                        style={{ color: '#ffffff' }}
                      >
                        <div className="edgtf-opening-hours-day">Friday*</div>
                      </div>
                      <div className="edgtf-opening-hours-line"></div>
                      <div
                        className="edgtf-opening-hours-time"
                        style={{ color: '#ffffff' }}
                      >
                        9:00 - 01:00
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget edgtf-opening-hours-widget">
                  <div className="edgtf-opening-hours-holder">
                    <div className="edgtf-opening-hours-holder-inner">
                      <div
                        className="edgtf-opening-hours-day-holder"
                        style={{ color: '#ffffff' }}
                      >
                        <div className="edgtf-opening-hours-day">Saturday*</div>
                      </div>
                      <div className="edgtf-opening-hours-line"></div>
                      <div
                        className="edgtf-opening-hours-time"
                        style={{ color: '#ffffff' }}
                      >
                        12:00 - 01:00
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget edgtf-opening-hours-widget">
                  <div className="edgtf-opening-hours-holder">
                    <div className="edgtf-opening-hours-holder-inner">
                      <div
                        className="edgtf-opening-hours-day-holder"
                        style={{ color: '#ffffff' }}
                      >
                        <div className="edgtf-opening-hours-day">Sunday</div>
                      </div>
                      <div className="edgtf-opening-hours-line"></div>
                      <div
                        className="edgtf-opening-hours-time"
                        style={{ color: '#ffffff' }}
                      >
                        12:00 - 22:00
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edgtf-column-content edgtf-grid-col-3 d-none">
                <div className="widget edgtf-blog-list-widget">
                  <h5 className="edgtf-widget-title">Latest Posts</h5>
                  <div
                    className="edgtf-blog-list-holder edgtf-bl-minimal  edgtf-bl-one-column edgtf-normal-space edgtf-bl-pag-no-pagination"
                    data-type="minimal"
                    data-number-of-posts="4"
                    data-number-of-columns="1"
                    data-space-between-items="normal"
                    data-orderby="date"
                    data-order="ASC"
                    data-image-size="thumbnail"
                    data-title-tag="h6"
                    data-excerpt-length="40"
                    data-post-info-section="yes"
                    data-post-info-image="yes"
                    data-post-info-author="yes"
                    data-post-info-date="yes"
                    data-post-info-category="yes"
                    data-post-info-comments="no"
                    data-post-info-like="no"
                    data-post-info-share="no"
                    data-pagination-type="no-pagination"
                    data-max-num-pages="5"
                    data-next-page="2"
                  >
                    <div className="edgtf-bl-wrapper edgtf-outer-space">
                      <ul className="edgtf-blog-list">
                        <li className="edgtf-bl-item edgtf-item-space clearfix">
                          <div className="edgtf-bli-inner">
                            <div className="edgtf-bli-content">
                              <h6 className="entry-title edgtf-post-title">
                                <Link to="#" title="Modern Interior Design">
                                  Modern Interior Design{' '}
                                </Link>
                              </h6>
                              <div className="edgtf-post-info-date entry-date published updated">
                                <Link to="#">February 20, 2018 </Link>
                                {/* <meta
																	content="UserComments: 0"> */}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="edgtf-bl-item edgtf-item-space clearfix">
                          <div className="edgtf-bli-inner">
                            <div className="edgtf-bli-content">
                              <h6 className="entry-title edgtf-post-title">
                                <Link to="#" title="Fun And Interesting People">
                                  Fun And Interesting People{' '}
                                </Link>
                              </h6>
                              <div className="edgtf-post-info-date entry-date published updated">
                                <Link to="#">February 20, 2018 </Link>
                                {/* <meta
																	content="UserComments: 0"> */}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="edgtf-bl-item edgtf-item-space clearfix">
                          <div className="edgtf-bli-inner">
                            <div className="edgtf-bli-content">
                              <h6 className="entry-title edgtf-post-title">
                                <Link to="#" title="Up And Coming Musicians">
                                  Up And Coming Musicians{' '}
                                </Link>
                              </h6>
                              <div className="edgtf-post-info-date entry-date published updated">
                                <Link to="#">February 20, 2018 </Link>
                                {/* <meta
																	content="UserComments: 0"> */}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="edgtf-bl-item edgtf-item-space clearfix">
                          <div className="edgtf-bli-inner">
                            <div className="edgtf-bli-content">
                              <h6 className="entry-title edgtf-post-title">
                                <Link to="#" title="Urban Vibe">
                                  Urban Vibe{' '}
                                </Link>
                              </h6>
                              <div className="edgtf-post-info-date entry-date published updated">
                                <Link to="#">February 20, 2018 </Link>
                                {/* <meta
																	content="UserComments: 0"> */}
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edgtf-column-content edgtf-grid-col-7 col-12">
                <div
                  id="text-3"
                  className="widget edgtf-footer-column-3 widget_text"
                >
                  <h5 className="edgtf-widget-title mb-0">CONTACT US</h5>
                  <div className="con-text">
                    <div className="textwidget">
                      <p className="first-p">
                        <Link to="mailto:contact@partypass.com">
                          contact@partypass.com
                        </Link>
                        <br />
                        <Link to="tel:1-562-867-5309">1-562-867-5309</Link>
                      </p>
                    </div>
                    <div
                      id="text-11"
                      className="widget edgtf-footer-column-3 widget_text"
                    >
                      <div className="textwidget">
                        <p className="last-p">
                          <Link to="https://www.google.rs/maps/place/Charging+Bull/@40.7055242,-74.0133806,20z/data=!4m5!3m4!1s0x0:0xc7db393ae1eff521!8m2!3d40.7055537!4d-74.0134436">
                            Broadway &amp; Morris St, New York
                          </Link>
                          <br />
                          <Link to="https://www.google.rs/maps/place/Autoklub+Vsetin+Czechia/@28.0903625,-80.5700215,17z/data=!3m1!4b1!4m5!3m4!1s0x88de116ac5f97e1b:0xbcd6048a3d229c61!8m2!3d28.0903625!4d-80.5678328">
                            123 6th St. Melbourne, FL
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edgtf-column-content edgtf-grid-col-5 col-12">
                <div
                  id="custom_html-2"
                  className="widget_text widget edgtf-footer-column-4 widget_custom_html"
                >
                  <h5 className="edgtf-widget-title mb-0">FOLLOW US</h5>
                  <div className="textwidget custom-html-widget">
                    <p className="mb-0 text-white">
                      For all the latest news and updates, follow us on
                    </p>
                  </div>
                  <div className="widget edgtf-social-icons-group-widget">
                    {' '}
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                      style={{ margin: '6px 8px 0 0px' }}
                      to="https://www.instagram.com/qodeinteractive/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://www.facebook.com/QodeInteractive/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://plus.google.com/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://twitter.com/QodeInteractive"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://www.youtube.com/QodeInteractiveVideos"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://vimeo.com/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edgtf-footer-bottom-holder ">
          <div className="edgtf-footer-bottom-inner edgtf-grid">
            <div className="edgtf-grid-row content-center">
              <div className="edgtf-grid-col-7 ps-2">
                <div
                  id="media_image-2"
                  className="widget edgtf-footer-bottom-column-1 widget_media_image"
                >
                  <div className="edgtf-logo-wrapper">
                    <Link to="#">
                      <img
                        width="40"
                        height="40"
                        alt="logo"
                        src="../images/emblem.png"
                      />
                      PartyPass
                    </Link>
                  </div>
                </div>
              </div>
              <div className="edgtf-grid-col-5 d-none">
                <div className="widget edgtf-social-icons-group-widget text-align-center">
                  {' '}
                  <Link
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    style={{ margin: '6px 8px 0 8px' }}
                    to="https://www.instagram.com/qodeinteractive/"
                    target="_blank"
                  >
                    <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                  </Link>
                  <Link
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    style={{ margin: '6px 8px 0 8px' }}
                    to="https://www.facebook.com/QodeInteractive/"
                    target="_blank"
                  >
                    <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                  </Link>
                  <Link
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    style={{ margin: '6px 8px 0 8px' }}
                    to="https://plus.google.com/"
                    target="_blank"
                  >
                    <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                  </Link>
                  <Link
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    style={{ margin: '6px 8px 0 8px' }}
                    to="https://twitter.com/QodeInteractive"
                    target="_blank"
                  >
                    <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                  </Link>
                  <Link
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    style={{ margin: '6px 8px 0 8px' }}
                    to="https://www.youtube.com/QodeInteractiveVideos"
                    target="_blank"
                  >
                    <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                  </Link>
                  <Link
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    style={{ margin: '6px 8px 0 8px' }}
                    to="https://vimeo.com/"
                    target="_blank"
                  >
                    <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                  </Link>
                </div>
              </div>
              <div className="edgtf-grid-col-5 ">
                <div className="widget edgtf-separator-widget">
                  <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                    <div
                      className="edgtf-separator"
                      style={{
                        borderColor: ' rgba(255, 255, 255, 0)',
                        borderStyle: 'solid',
                        marginTop: '-8px',
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  id="text-6"
                  className="widget edgtf-footer-bottom-column-3 widget_text"
                >
                  <div className="textwidget text-md-start">
                    <p className="text-white">
                      © 2024{' '}
                      <Link
                        to="https://qodeinteractive.com/"
                        target="_blank"
                        rel="noopener"
                      >
                        PartyPass
                      </Link>
                      , All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
