import ProfileUpdate from '../Customer/Pages/Auth/Profile/ProfileUpdate';
import Home from '../Customer/Pages/Home/Home';
import MyOrder from '../Customer/Pages/Auth/MyOrder/MyOrder';

// Private Routes
export const privateRoutes = [
  { path: 'home', element: <Home /> },
  { path: 'profile-update', element: <ProfileUpdate /> },
  { path: 'my-order', element: <MyOrder /> },
];
