import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const ThankYouCheckOut = () => {
  return (
    <>
      <div className="edgtf-woocommerce-page woocommerce-order-received edgtf-predefined-text-skin edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* html new add start */}

            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-order">
                            <p className="woocommerce-notice d-none woocommerce-notice--success woocommerce-thankyou-order-received">
                              Thank you. Your order has been received.
                            </p>
                            <h2 className="woocommerce-order-details__title text-white">
                              Thank you. Your order has been received.
                            </h2>

                            <ul className="woocommerce-order-overview woocommerce-thankyou-order-details order_details">
                              <li className="woocommerce-order-overview__order order">
                                Order number: <strong>4883</strong>
                              </li>

                              <li className="woocommerce-order-overview__date date">
                                Date: <strong>October 30, 2024</strong>
                              </li>

                              <li className="woocommerce-order-overview__total total">
                                Total:{' '}
                                <strong>
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">
                                        $
                                      </span>
                                      120
                                    </bdi>
                                  </span>
                                </strong>
                              </li>

                              <li className="woocommerce-order-overview__payment-method method">
                                Payment method:{' '}
                                <strong>Direct bank transfer</strong>
                              </li>
                            </ul>

                            <section className="woocommerce-order-details">
                              <h2 className="woocommerce-order-details__title text-white">
                                Order details
                              </h2>

                              <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                                <thead>
                                  <tr>
                                    <th className="woocommerce-table__product-name product-name">
                                      Product
                                    </th>
                                    <th className="woocommerce-table__product-table product-total">
                                      Total
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr className="woocommerce-table__line-item order_item">
                                    <td className="woocommerce-table__product-name product-name">
                                      <a href="https://offbeat.qodeinteractive.com/product/blurrybus/">
                                        Blurrybus
                                      </a>{' '}
                                      <strong className="product-quantity">
                                        ×&nbsp;4
                                      </strong>{' '}
                                    </td>

                                    <td className="woocommerce-table__product-total product-total">
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          120
                                        </bdi>
                                      </span>{' '}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th scope="row">Subtotal:</th>
                                    <td>
                                      <span className="woocommerce-Price-amount amount">
                                        <span className="woocommerce-Price-currencySymbol">
                                          $
                                        </span>
                                        120
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Shipping:</th>
                                    <td>Free shipping</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Payment method:</th>
                                    <td>Direct bank transfer</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total:</th>
                                    <td>
                                      <span className="woocommerce-Price-amount amount">
                                        <span className="woocommerce-Price-currencySymbol">
                                          $
                                        </span>
                                        120
                                      </span>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </section>

                            <section className="woocommerce-customer-details d-none">
                              <section className="woocommerce-columns woocommerce-columns--2 woocommerce-columns--addresses col2-set addresses">
                                <div className="woocommerce-column woocommerce-column--1 woocommerce-column--billing-address col-1">
                                  <h2 className="woocommerce-column__title text-white">
                                    Billing address
                                  </h2>

                                  <address>
                                    ajay demo
                                    <br />
                                    Gatlinburg, TN 37738, Gatlinburg, TN 37738
                                    <br />
                                    Gatlinburg, TN 37738
                                    <p className="woocommerce-customer-details--phone">
                                      +919877781112
                                    </p>
                                    <p className="woocommerce-customer-details--email">
                                      ajay.chayal@yatilabs.com
                                    </p>
                                  </address>
                                </div>

                                <div className="woocommerce-column woocommerce-column--2 woocommerce-column--shipping-address col-2">
                                  <h2 className="woocommerce-column__title text-white">
                                    Shipping address
                                  </h2>
                                  <address>
                                    ajay demo
                                    <br />
                                    Gatlinburg, TN 37738, Gatlinburg, TN 37738
                                    <br />
                                    Gatlinburg, TN 37738
                                  </address>
                                </div>
                              </section>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* html new add end */}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouCheckOut;
