import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  getLoginCustomer,
  logoutCustomer,
} from '../../../../Utils/LocalStorage';

const Header = () => {
  const navigate = useNavigate();
  const customer = getLoginCustomer();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const handleSignOut = async () => {
    await logoutCustomer();
    await handleNavigation('/');
  };

  return (
    <>
      <header className="edgtf-page-header top-header-customer">
        <div className="edgtf-menu-area edgtf-menu-left">
          <div className="edgtf-vertical-align-containers">
            <div className="edgtf-position-left">
              <div className="edgtf-position-left-inner">
                <div className="edgtf-logo-wrapper">
                  <Link to="/">
                    <img
                      width="40"
                      height="40"
                      alt="logo"
                      src="../images/emblem.png"
                    />
                    PartyPass
                  </Link>
                </div>

                <nav className="edgtf-main-menu edgtf-drop-down edgtf-default-nav menu-hh">
                  <input type="checkbox" id="checkbox" />
                  <label htmlFor="checkbox" className="check-click">
                    <img src="../images/menu-c.png" alt="" />
                  </label>
                  <ul id="menu-main-menu-navigation" className="clearfix">
                    <li className="menu-item edgtf-active-item">
                      <Link to="#" className="current ">
                        <span className="item_outer">
                          <span className="item_text">Home</span>
                        </span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="#" className="current ">
                        <span className="item_outer">
                          <span className="item_text">Events</span>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="edgtf-position-right">
              <div className="edgtf-position-right-inner">
                {customer ? (
                  <div className="col d-flex">
                    <Link to="/cart">
                      <img
                        src="../images/cart.png"
                        alt="cart"
                        style={{ width: '40px' }}
                      />
                    </Link>
                    <div className="dropdown">
                      <div
                        className="user-id"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            customer?.profile_picture &&
                            customer?.profile_picture?.fullUrl
                              ? customer?.profile_picture?.fullUrl
                              : '../images/profile.png'
                          }
                          alt=""
                        />
                      </div>
                      <ul className="dropdown-menu drop-menu-list">
                        <li>
                          <Link className="dropdown-item" to="/profile-update">
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/my-order">
                            My Orders
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={handleSignOut}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => handleNavigation('/signup')}
                      style={{ color: '#ffffff', borderColor: '#ffffff' }}
                      className="edgtf-btn edgtf-btn-custom-border-hover edgtf-btn-custom-hover-bg edgtf-btn-custom-hover-color edgtf-btn-outline"
                      data-hover-color="#000000"
                      data-hover-bg-color="#ffffff"
                      data-hover-border-color="#ffffff"
                    >
                      <span className="edgtf-btn-text">Sign Up</span>
                    </button>
                    <Link
                      to="/login"
                      style={{ marginLeft: '20px' }}
                      className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                    >
                      <span className="edgtf-btn-text">Login</span>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <form className="edgtf-search-cover" method="get">
          <div className="edgtf-form-holder-outer">
            <div className="edgtf-form-holder">
              <div className="edgtf-form-holder-inner">
                <input
                  type="text"
                  placeholder="Search here..."
                  name="s"
                  className="edgtf_search_field"
                  autoComplete="off"
                />
                <Link
                  className="edgtf-search-close edgtf-search-close-icon-pack"
                  to="#"
                >
                  <span
                    aria-hidden="true"
                    className="edgtf-icon-font-elegant icon_close "
                  ></span>{' '}
                </Link>
              </div>
            </div>
          </div>
        </form>
      </header>
      <Link id="edgtf-back-to-top" to="#">
        <span className="edgtf-icon-stack edgtf-front-side">
          <span
            aria-hidden="true"
            className="edgtf-icon-font-elegant arrow_triangle-up "
          ></span>{' '}
        </span>
        <span className="edgtf-icon-stack edgtf-back-side">
          <span
            aria-hidden="true"
            className="edgtf-icon-font-elegant arrow_triangle-up "
          ></span>{' '}
        </span>
      </Link>
      {/* new header */}
    </>
  );
};

export default Header;
