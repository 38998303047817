// src/Utils/LocalStorage.js
import { EncryptStorage } from 'encrypt-storage';

// Initialize the encrypt storage with the secret key from the environment variables
const encryptStorage = new EncryptStorage(process.env.REACT_APP_SECRET_KEY, {
  doNotEncryptValues: false,
  prefix: '@partypass',
});

export const saveItem = (key, value) => {
  encryptStorage.setItem(key, value);
};

export const getItem = (key) => {
  return encryptStorage.getItem(key);
};

// Function to save a JSON object to local storage
export const saveObject = (key, object) => {
  encryptStorage.setItem(key, JSON.stringify(object));
};

// Function to retrieve a JSON object from local storage
export const getObject = (key) => {
  const item = encryptStorage.getItem(key);
  return item ? item : null;
};

// Function to remove an item from local storage
export const removeObject = (key) => {
  encryptStorage.removeItem(key);
};

// Function to set the logged-in Customer
export const setLoginCustomer = (user) => {
  saveObject('loggedInCustomer', user);
  saveItem('isCustomerLoggedIn', true);
};
// Function to set the logged-in Seller
export const setLoginSeller = (user) => {
  saveObject('loggedInSeller', user);
  saveItem('isSellerLoggedIn', true);
};

// Function to get the logged-in Customer
export const getLoginCustomer = () => {
  return getObject('loggedInCustomer');
};
// Function to get the logged-in Seller
export const getLoginSeller = () => {
  return getObject('loggedInSeller');
};

export const isCustomerLoggedIn = () => {
  return encryptStorage.getItem('isCustomerLoggedIn');
};

export const isSellerLoggedIn = () => {
  return encryptStorage.getItem('isSellerLoggedIn');
};

// Function to log out the Customer
export const logoutCustomer = () => {
  removeObject('loggedInCustomer');
  encryptStorage.removeItem('isCustomerLoggedIn');
  encryptStorage.removeItem('customerToken');
};

// Function to log out the Seller
export const logoutSeller = () => {
  removeObject('loggedInSeller');
  encryptStorage.removeItem('isSellerLoggedIn');
  encryptStorage.removeItem('sellerToken');
};

// Function to clear storage
export const clearStorage = () => {
  encryptStorage.clear();
};

export const clearItem = (item) => {
  encryptStorage.removeItem(item);
};
