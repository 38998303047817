import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getLoginCustomer } from '../LocalStorage';

const PublicRoute = ({ element, redirectTo }) => {
  const user = getLoginCustomer();
  return user ? <Navigate to={redirectTo} /> : element;
};

PublicRoute.propTypes = {
  element: PropTypes.any,
  redirectTo: PropTypes.string,
};
export default PublicRoute;
