/**
 * Handles file upload for different cases
 * @param {string} ownerClass - The owner class (App\\Models\\EntityHasProficiencyType,
             App\\Models\\Range, App\\Models\\User )
 * @param {number} ownerId - The owner ID
 * @param {number} type - The file type
 * (GENERAL = 1,USER_PROFILE_PICTURE = 2,USER_GOVT_ID_FRONT = 3,USER_GOVT_ID_BACK = 4,
 * RANGE_LOGO = 5,RANGE_BANNER = 6,RANGE_GALLERY = 7,PROFICIENCY_LOGO = 8)
 * @param {File} file - The file to upload
 * @returns {Promise} - The response from the server
 */

import axiosInstance from './axiosInstance';
import { UPLOAD_ENDPOINT } from './Endpoints';

export const uploadFile = async (ownerClass, ownerId, type, file) => {
  const formdata = new FormData();
  formdata.append('owner_class', ownerClass);
  formdata.append('owner_id', ownerId.toString());
  formdata.append('type', type.toString());
  formdata.append('file_name', file);

  const response = await axiosInstance.post(UPLOAD_ENDPOINT, formdata);
  if (response.data.code === 200) {
    return response;
  } else {
    return response.data.message;
  }
};
