import React from 'react';
import Header from '../Home/Components/Header';
import Footer from '../Home/Components/Footer';
import { Link } from 'react-router-dom';

const Setting = () => {
  return (
    <>
      <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            {/* new html start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>

                          <h2 className="text-white">
                            Update My PartyPass Profile
                          </h2>

                          <form
                            className="woocommerce-form woocommerce-form-login login setting-form-color"
                            method="post"
                          >
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="firstName">
                                First Name
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="firstName"
                                placeholder="First Name"
                              />
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="lastName">
                                Last Name
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="lastName"
                                id="lastName"
                                autoComplete="lastName"
                                placeholder="Last Name"
                              />{' '}
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="dob">
                                Date of Birth
                                <span className="required">*</span>
                              </label>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="date"
                              >
                                <input
                                  className="wpcf7-form-control wpcf7-date wpcf7-validates-as-date"
                                  aria-invalid="false"
                                  placeholder="Time*"
                                  value=""
                                  type="date"
                                  name="dob"
                                />
                              </span>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide w-d">
                              <label htmlFor="gender">
                                Gender
                                <span className="required">*</span>
                              </label>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="date"
                              >
                                <select
                                  name="gender"
                                  id="gender"
                                  className="form-select f-s wpcf7-date wpcf7-validates-as-date"
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                              </span>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide w-d">
                              <label htmlFor="country">
                                Country
                                <span className="required">*</span>
                              </label>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="date"
                              >
                                <select
                                  name="gender"
                                  id="gender"
                                  className="form-select f-s wpcf7-date wpcf7-validates-as-date"
                                >
                                  <option value="india">India</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Angola">Angola</option>
                                </select>
                              </span>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="city">
                                City
                                <span className="required">*</span>
                              </label>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="date"
                              >
                                <input
                                  className="wpcf7-form-control wpcf7-date wpcf7-validates-as-date"
                                  aria-invalid="false"
                                  placeholder="City*"
                                  value=""
                                  type="text"
                                  name="city"
                                />
                              </span>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="number">
                                Phone Number
                                <span className="required">*</span>
                              </label>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="number"
                              >
                                <input
                                  className="wpcf7-form-control wpcf7-date wpcf7-validates-as-date"
                                  aria-invalid="false"
                                  placeholder="Phone Number*"
                                  value=""
                                  type="tell"
                                  name="number"
                                />
                              </span>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="email">
                                Email
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="email"
                                id="email"
                                placeholder="Email"
                              />{' '}
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="Instagram">@Instagram</label>
                              <input
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="Instagram"
                                id="Instagram"
                                placeholder="Instagram"
                              />{' '}
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="TikTok">@TikTok</label>
                              <input
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="TikTok"
                                id="TikTok"
                                placeholder="TikTok"
                              />{' '}
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="Facebook">Facebook</label>
                              <input
                                type="text"
                                className="woocommerce-Input woocommerce-Input--text input-text"
                                name="Facebook"
                                id="Facebook"
                                placeholder="Facebook"
                              />{' '}
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="password">
                                Password&nbsp;
                                <span className="required">*</span>
                              </label>
                              <span className="password-input">
                                <input
                                  className="woocommerce-Input woocommerce-Input--text input-text"
                                  type="password"
                                  name="password"
                                  id="password"
                                  autoComplete="current-password"
                                />
                                <span className="show-password-input"></span>
                              </span>
                            </p>

                            <p className="form-row">
                              <label className="d-none woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme">
                                <input
                                  className="woocommerce-form__input woocommerce-form__input-checkbox"
                                  name="rememberme"
                                  type="checkbox"
                                  id="rememberme"
                                  value="forever"
                                />{' '}
                                <span>Remember me</span>
                              </label>
                              <input
                                type="hidden"
                                id="woocommerce-login-nonce"
                                name="woocommerce-login-nonce"
                                value="eb0d0efcda"
                              />
                              <input
                                type="hidden"
                                name="_wp_http_referer"
                                value="/my-account/"
                              />{' '}
                              <button
                                type="submit"
                                className="woocommerce-button button woocommerce-form-login__submit"
                                name="save"
                                value="save"
                              >
                                Save
                              </button>
                            </p>
                            <p className="woocommerce-LostPassword lost_password d-none">
                              <Link to="#">Lost your password?</Link>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new html start */}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
