import { Link } from 'react-router-dom';
import EventCard from './Components/EventCard';
import { useEffect, useState } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Logger from '../../../Utils/Logger';
import axiosInstance from '../../../Utils/axiosInstance';
import { EVENTS_ENDPOINT } from '../../../Utils/Endpoints';

const Main = () => {
  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(EVENTS_ENDPOINT);
      if (response.data.code === 200) {
        setEventList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            <div className="edgtf-content" style={{ marginTop: '-90px' }}>
              <div className="edgtf-content-inner">
                <div className="edgtf-full-width">
                  <div className="edgtf-full-width-inner">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div
                                    className="hero-section"
                                    style={{
                                      backgroundImage:
                                        'url("../images/hero-background.jpg")',
                                    }}
                                  >
                                    <h3>Start with</h3>
                                    <h1>Partypass</h1>
                                    <h2>Feel the beat of the night</h2>
                                    <Link
                                      to="#"
                                      className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                    >
                                      Expore Events
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  {/* Events Listing START */}
                                  <div className="events-listing">
                                    <var className="edgtf-st-tagline">
                                      Upcoming Events{' '}
                                    </var>
                                    <div className="outer outer-new">
                                      {!isLoading &&
                                        eventList?.map((item, index) => (
                                          <EventCard
                                            event={item}
                                            imageUser={item?.banner?.fullUrl}
                                            heading={item?.name}
                                            text={item?.startDateTimeFormat}
                                            key={index}
                                          />
                                        ))}

                                      {/*  <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/84ef81007b7b1501950d0db585deee4f.jpeg"
                                        heading="AEPI Hoco Alumni"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                        <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/053d8d6d6d7e3c153bd714048a213635.jpeg"
                                        heading="AFTER PROM 2025 @HILLS STATE"
                                        text="May 9, 2025 10:00 PM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/d7fa517332a33869103e747e299fdfe7.png"
                                        heading="Friday @ The DL 10/11"
                                        text="October 11, 2024 10:00 PM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/b360646cbceac0a6d449785af16eb498.png"
                                        heading="The Purge @Blend Willamsburg"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/674e355888aa530355d68683d4005f7e.jpg"
                                        heading="AVENUES HAUNTED HALLOWEEN"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/3418079973dc14f83e373b7918e2f70c.png"
                                        heading=" Daintree Rooftop Saturday Halloween
                                            Party - Tower Of Terror 10/26"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/c5d9f0c96b0c2f9afd7aacf1a3c9d586.png"
                                        heading=" Daintree Rooftop Saturday Halloween
                                            Party - Tower Of Terror 10/26"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/a6c93958d1f0d018287a51c4ce9746c8.jpeg"
                                        heading="SATURDAYS @ THE DL"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/59b8ad4af04bf2e9b10deba2d2edadcc.png"
                                        heading="A View to Kill Halloween at Bar Hugo
                                            Rooftop at the Hugo Hotel Soho"
                                        text="October 26, 2024 11:00 AM"
                                      /> */}
                                    </div>
                                  </div>
                                  {/* Events Listing END */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="edgtf-row-grid-section-wrapper edgtf-content-aligment-center">
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1522835334066">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="edgtf-section-title-holder  ">
                                        <div className="edgtf-st-inner">
                                          <var className="edgtf-st-tagline">
                                            Lead the fun.{' '}
                                          </var>
                                          <h1 className="edgtf-st-title">
                                            Find Your Event Now
                                            <span className="edgtf-st-title-dot">
                                              .
                                            </span>
                                          </h1>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '84px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="edgtf-rf-holder edgtf-rf-light">
                                        <form
                                          className="edgtf-rf"
                                          target="_blank"
                                          name="edgtf-rf"
                                        >
                                          <div className="edgtf-rf-row clearfix">
                                            <div className="edgtf-rf-col-holder">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <select
                                                  name="partySize"
                                                  className="edgtf-ot-people"
                                                >
                                                  <option value="1">
                                                    1 Person
                                                  </option>
                                                  <option value="2">
                                                    2 People
                                                  </option>
                                                  <option value="3">
                                                    3 People
                                                  </option>
                                                  <option value="4">
                                                    4 People
                                                  </option>
                                                  <option value="5">
                                                    5 People
                                                  </option>
                                                  <option value="6">
                                                    6 People
                                                  </option>
                                                  <option value="7">
                                                    7 People
                                                  </option>
                                                  <option value="8">
                                                    8 People
                                                  </option>
                                                  <option value="9">
                                                    9 People
                                                  </option>
                                                  <option value="10">
                                                    10 People
                                                  </option>
                                                </select>
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-group"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-separator">
                                              <span>for</span>
                                            </div>
                                            <div className="edgtf-rf-col-holder">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <input
                                                  type="text"
                                                  value="10/07/2024"
                                                  className="edgtf-ot-date"
                                                  name="startDate"
                                                />
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-calendar"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-separator">
                                              <span>at</span>
                                            </div>
                                            <div className="edgtf-rf-col-holder edgtf-rf-time-col">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <select
                                                  name="ResTime"
                                                  className="edgtf-ot-time"
                                                >
                                                  <option value="6:30am">
                                                    6:30 am
                                                  </option>
                                                  <option value="7:00am">
                                                    7:00 am
                                                  </option>
                                                  <option value="7:30am">
                                                    7:30 am
                                                  </option>
                                                  <option value="8:00am">
                                                    8:00 am
                                                  </option>
                                                  <option value="8:30am">
                                                    8:30 am
                                                  </option>
                                                  <option value="9:00am">
                                                    9:00 am
                                                  </option>
                                                  <option value="9:30am">
                                                    9:30 am
                                                  </option>
                                                  <option value="10:00pm">
                                                    10:00 am
                                                  </option>
                                                  <option value="10:30am">
                                                    10:30 am
                                                  </option>
                                                  <option value="11:00am">
                                                    11:00 am
                                                  </option>
                                                  <option value="11:30am">
                                                    11:30 am
                                                  </option>
                                                  <option value="12:00pm">
                                                    12:00 pm
                                                  </option>
                                                  <option value="12:30pm">
                                                    12:30 pm
                                                  </option>
                                                  <option value="1:00pm">
                                                    1:00 pm
                                                  </option>
                                                  <option value="1:30pm">
                                                    1:30 pm
                                                  </option>
                                                  <option value="2:00pm">
                                                    2:00 pm
                                                  </option>
                                                  <option value="2:30pm">
                                                    2:30 pm
                                                  </option>
                                                  <option value="3:00pm">
                                                    3:00 pm
                                                  </option>
                                                  <option value="3:30pm">
                                                    3:30 pm
                                                  </option>
                                                  <option value="4:00pm">
                                                    4:00 pm
                                                  </option>
                                                  <option value="4:30pm">
                                                    4:30 pm
                                                  </option>
                                                  <option value="5:00pm">
                                                    5:00 pm
                                                  </option>
                                                  <option value="5:30pm">
                                                    5:30 pm
                                                  </option>
                                                  <option value="6:00pm">
                                                    6:00 pm
                                                  </option>
                                                  <option value="6:30pm">
                                                    6:30 pm
                                                  </option>
                                                  <option
                                                    value="7:00pm"
                                                    selected="selected"
                                                  >
                                                    7:00 pm
                                                  </option>
                                                  <option value="7:30pm">
                                                    7:30 pm
                                                  </option>
                                                  <option value="8:00pm">
                                                    8:00 pm
                                                  </option>
                                                  <option value="8:30pm">
                                                    8:30 pm
                                                  </option>
                                                  <option value="9:00pm">
                                                    9:00 pm
                                                  </option>
                                                  <option value="9:30pm">
                                                    9:30 pm
                                                  </option>
                                                  <option value="10:00pm">
                                                    10:00 pm
                                                  </option>
                                                  <option value="10:30pm">
                                                    10:30 pm
                                                  </option>
                                                  <option value="11:00pm">
                                                    11:00 pm
                                                  </option>
                                                  <option value="11:30pm">
                                                    11:30 pm
                                                  </option>
                                                </select>
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-clock"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-holder edgtf-rf-btn-holder">
                                              <button
                                                type="submit"
                                                className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                              >
                                                <span className="edgtf-btn-text">
                                                  Search
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex edgtf-content-aligment-left">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder edgtf-two-columns edgtf-responsive-mode-1024">
                                    <div className="edgtf-eh-item edgtf-horizontal-alignment-center">
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-3391">
                                          <div className="edgtf-single-image-holder">
                                            <div className="edgtf-si-inner in-ner">
                                              <img
                                                className="attachment-full size-full"
                                                alt="c"
                                                srcSet="images/h1-img-1.jpg 800w, images/h1-img-1-150x94.jpg 150w, images/h1-img-1-300x188.jpg 300w, images/h1-img-1-768x480.jpg 768w"
                                                sizes="(max-width: 800px) 100vw, 800px"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="edgtf-eh-item    "
                                      data-item-className="edgtf-eh-custom-2601"
                                      data-1280-1600="177px 57px 230px 0"
                                      data-1024-1280="177px 13px 230px 0 "
                                      data-768-1024="0 247px 230px 120px"
                                      data-680-768="0 55px 230px 80px"
                                      data-680="80px 15px 230px 15px"
                                    >
                                      <div className="edgtf-eh-item-inner  edgtf-in">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-2601">
                                          <div className="edgtf-section-title-holder ">
                                            <div className="edgtf-st-inner ">
                                              <var className="edgtf-st-tagline">
                                                Be at right place.{' '}
                                              </var>
                                              <h1 className="edgtf-st-title">
                                                Nightclub NYC
                                                <span className="edgtf-st-title-dot">
                                                  .
                                                </span>
                                              </h1>
                                              <p
                                                className="edgtf-st-text"
                                                style={{
                                                  color: '#ffffff',
                                                  marginTop: '23px',
                                                }}
                                              >
                                                When searching for the right
                                                place to get lost and enjoy new,
                                                alternative music, Offbeat
                                                lounge bar is the perfect
                                                choice. With cozy, yes
                                                progressive interior, and kind
                                                staff, we are the best place to
                                                get lost during work days or on
                                                weekends. Enjoy live music
                                                shows.
                                              </p>
                                            </div>
                                          </div>
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: '61px' }}
                                          >
                                            <span className="vc_empty_space_inner"></span>
                                          </div>
                                          <Link
                                            to=""
                                            target="_self"
                                            style={{
                                              color: '#ffffff',
                                              borderColor: '#ffffff',
                                            }}
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-outline edgtf-btn-orange-black edgtf-btn-custom-hover-bg edgtf-btn-custom-border-hover edgtf-btn-custom-hover-color"
                                            data-hover-color="#000000"
                                            data-hover-bg-color="#ffffff"
                                            data-hover-border-color="#ffffff"
                                          >
                                            <span className="edgtf-btn-text">
                                              Read More
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            data-parallax-bg-image="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/h1-video-img.jpg"
                            className="vc_row wpb_row vc_row-fluid vc_row-o-full-height vc_row-o-columns-middle vc_row-o-content-middle vc_row-flex edgtf-parallax-row-holder"
                          >
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder  edgtf-eh-full-height edgtf-one-column  edgtf-responsive-mode-768 edgtf-one-column-alignment-center">
                                    <div className="edgtf-eh-item   edgtf-horizontal-alignment-center ">
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content">
                                          <div className="edgtf-video-button-holder ">
                                            <Link
                                              className="edgtf-video-button-play"
                                              to="#"
                                            >
                                              <span className="edgtf-video-button-play-inner">
                                                <span className="edgtf-video-button-circle">
                                                  <span className="edgtf-video-button-triangle"></span>
                                                </span>
                                              </span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="edgtf-row-grid-section-wrapper edgtf-content-aligment-left"
                            style={{ backgroundColor: '#000000' }}
                          >
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1520843122533">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div
                                        className="edgtf-section-title-holder  "
                                        style={{ textAlign: 'center' }}
                                      >
                                        <div className="edgtf-st-inner">
                                          <var className="edgtf-st-tagline">
                                            Our finnest{' '}
                                          </var>
                                          <h2 className="edgtf-st-title">
                                            CRAFT BEER Selection
                                            <span className="edgtf-st-title-dot">
                                              .
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '60px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="edgtf-elements-holder   edgtf-two-columns  edgtf-responsive-mode-1024 edgtf-ui">
                                        <div
                                          className="edgtf-eh-item    "
                                          data-item-className="edgtf-eh-custom-8340"
                                          data-1280-1600="0 0 0 116px"
                                          data-1024-1280="0 0 0 116px"
                                          data-768-1024="0 0 0 0"
                                          data-680-768="0 0 0 0"
                                          data-680="0 0 0 0"
                                        >
                                          <div className="edgtf-eh-item-inner">
                                            <div
                                              className="edgtf-eh-item-content edgtf-eh-custom-8340"
                                              // style={{ padding: '0 0 0 157px' }}
                                            >
                                              <div className="offbeat-pricing-list clearfix offbeat-pli-light offbeat-pli-normal-space">
                                                <div className="offbeat-pl-wrapper">
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-1.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Summit Craft
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$2.95</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Saga IPA, Oatmeal
                                                            Stout, High Sticke
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-3.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Barley Garden
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>Finest IPA</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-5.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Brooklyn{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.15</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Defender IPA,
                                                            Scorcher Ipa,
                                                            Sorachi
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-7.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Hobgoblin{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.45</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Hobgoblin Gold,
                                                            Hobgoblin Ipa
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-9.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Bluemoon{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Belgian White, Mango
                                                            Wheat
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-11.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Brewdog{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.80</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Punk IPA, 5AM Saint,
                                                            Elvis Juice
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="edgtf-eh-item    "
                                          data-item-className="edgtf-eh-custom-5202"
                                          data-1280-1600="0 93px 0 0px"
                                          data-768-1024="0 0 0 0"
                                          data-680-768="0 0 0 0"
                                          data-680="0 0 0 0"
                                        >
                                          <div className="edgtf-eh-item-inner">
                                            <div
                                              className="edgtf-eh-item-content edgtf-eh-custom-5202"
                                              // style={{
                                              //   padding: '0 144px 0 0px',
                                              // }}
                                            >
                                              <div className="offbeat-pricing-list clearfix offbeat-pli-light offbeat-pli-normal-space">
                                                <div className="offbeat-pl-wrapper">
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-2.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Weisse{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$2.95</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Saga IPA, Oatmeal
                                                            Stout, High Sticke
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-4.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Bomber{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>Finest IPA</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-6.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Hill Brothers
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.15</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Defender IPA,
                                                            Scorcher Ipa,
                                                            Sorachi
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-8.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Paulaner{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.45</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Hobgoblin Gold,
                                                            Hobgoblin Ipa
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-10.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Culture On Tap
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Belgian White, Mango
                                                            Wheat
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-12.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Knocker{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.80</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Punk IPA, 5AM Saint,
                                                            Elvis Juice
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '16px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="vc_row wpb_row vc_inner vc_row-fluid edgtf-content-aligment-center">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                          <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                              <Link
                                                to="#"
                                                target="_self"
                                                style={{
                                                  color: '#ffffff',
                                                  backgroundColor: '#f45f0b',
                                                  textTransform: 'uppercase',
                                                }}
                                                className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                              >
                                                <span className="edgtf-btn-text">
                                                  View Menu
                                                </span>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="vc_row wpb_row vc_row-fluid vc_custom_1523350595066 vc_row-o-full-height vc_row-o-columns-top vc_row-flex"
                            style={{
                              backgroundImage: 'url("../images/h1-img-2.jpg")',
                            }}
                          >
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="offbeat-event-list-holder offbeat-event-list-simple edgtf-light-skin edgtf-el-no-columns edgtf-normal-space">
                                    <div className="offbeat-event-list-holder-inner">
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 1{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 2{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 3{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 4{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid vc_custom_1520262019848 vc_row-has-fill vc_row-o-content-middle vc_row-flex edgtf-content-aligment-center">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder edgtf-two-columns edgtf-responsive-mode-1024 ">
                                    <div
                                      className="edgtf-eh-item    "
                                      data-item-className="edgtf-eh-custom-9820"
                                      data-1280-1600="42px 0px 75px 93px "
                                      data-1024-1280="42px 0 75px 65px"
                                      data-768-1024="42px 0px 86px 0px"
                                      data-680-768="42px 0px 86px 0px"
                                      data-680="42px 10px 86px 10px"
                                    >
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-9820 edgtf-eh-2">
                                          <div className="wpb_text_column wpb_content_element">
                                            <div className="wpb_wrapper">
                                              <h3>
                                                Don’t Miss Out Fun. Subscribe.
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="edgtf-eh-item"
                                      data-item-className="edgtf-eh-custom-8405"
                                      data-1280-1600="0 118px 0 57px "
                                      data-1024-1280="0 95px 0 60px "
                                      data-768-1024="0 172px 54px 172px "
                                      data-680-768="0 45px 54px 46px"
                                      data-680="0 10px 54px 10px"
                                    >
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-8405 edgtf-eh-3">
                                          <div
                                            className="wpcf7 no-js"
                                            id="wpcf7-f5-p21-o1"
                                            lang="en-US"
                                            dir="ltr"
                                          >
                                            <div className="screen-reader-response">
                                              <p
                                                role="status"
                                                aria-live="polite"
                                                aria-atomic="true"
                                              ></p>
                                              <ul></ul>
                                            </div>
                                            <form
                                              className="wpcf7-form init cf7_custom_style_1 demo"
                                              aria-label="Contact form"
                                              noValidate="novalidate"
                                              data-status="init"
                                            >
                                              <div className="edgtf-two-columns-form-without-space">
                                                <div className="edgtf-column-left">
                                                  <p>
                                                    <span
                                                      className="wpcf7-form-control-wrap"
                                                      data-name="email"
                                                    >
                                                      <input
                                                        size="40"
                                                        maxLength="80"
                                                        className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email"
                                                        aria-invalid="false"
                                                        placeholder="enter your email"
                                                        value=""
                                                        type="email"
                                                        name="email"
                                                      />
                                                    </span>
                                                  </p>
                                                </div>
                                                <div className="edgtf-column-right">
                                                  <p>
                                                    <input
                                                      className="wpcf7-form-control wpcf7-submit has-spinner"
                                                      type="submit"
                                                      value="SUBSCRIBE"
                                                    />
                                                  </p>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner vc_custom_1519635204890">
                                <div className="wpb_wrapper">
                                  <div className="wpb_widgetised_column wpb_content_element">
                                    <div className="wpb_wrapper">
                                      <div className="widget widget_edgtf_instagram_widget"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
