import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  EVENTS_ENDPOINT,
  ORGANIZATION_FRONT_ENDPOINT,
} from '../../../../Utils/Endpoints';
import Logger from '../../../../Utils/Logger';
import EventCard from '../Components/EventCard';
import PropTypes from 'prop-types';
import MapComponent from './MapComponent';

const OrgEvent = ({ orgId }) => {
  const [organization, setOrganization] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    fetchOrganization();
    fetchEvent();
  }, []);

  const fetchOrganization = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_FRONT_ENDPOINT + `/${orgId}`
      );
      if (response.data.code === 200) {
        setOrganization(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENTS_ENDPOINT + `?organization_id=${orgId}`
      );
      if (response.data.code === 200) {
        setEventList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            {/* new html start */}
            {!isLoading && (
              <div className="edgtf-content">
                <div className="edgtf-content-inner">
                  <div
                    className="edgtf-title-holder edgtf-standard-type edgtf-title-va-header-bottom"
                    style={{ height: '150px', backgroundColor: '#000000' }}
                    data-height="150"
                  >
                    <div
                      className="edgtf-title-wrapper"
                      style={{ height: '150px' }}
                    >
                      <div className="edgtf-title-inner">
                        <div className="edgtf-grid">
                          <h2 className="edgtf-page-title entry-title">
                            {organization?.name}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offbeat-event-single-holder">
                    <div className="edgtf-full-width">
                      <div className="edgtf-full-width-inner pt-0">
                        <div className="edgtf-grid p-l-r">
                          <div className="offbeat-event-top-holder edgtf-grid-row edgtf-grid-huge-gutter preview-tt">
                            <div className="offbeat-event-top-right edgtf-grid-col-4 off-beat px-md-2">
                              <div className="offbeat-event-featured-image pt-1">
                                <img
                                  width="800"
                                  height="943"
                                  src={
                                    organization?.banner?.fullUrl
                                      ? organization?.banner?.fullUrl
                                      : '../images/thumbnail.png'
                                  }
                                  className="attachment-full size-full wp-post-image hh-img"
                                  alt="efqw"
                                  decoding="async"
                                  fetchPriority="high"
                                  sizes="(max-width: 800px) 100vw, 800px"
                                />
                              </div>
                              <div className="user-ticket-details">
                                <h4 className="offbeat-event-info-title">
                                  Details
                                </h4>
                                <div className="offbeat-event-info-item offbeat-event-date">
                                  <span className="offbeat-event-info-item-title">
                                    Address:
                                  </span>
                                  <span className="offbeat-event-info-item-desc">
                                    {organization?.location?.addressString}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="offbeat-event-top-left edgtf-grid-col-8 px-md-2">
                              <div className="offbeat-event-content-wrap clearfix pb-5">
                                <div className="offbeat-event-text-main content-center">
                                  <div className="offbeat-event-content">
                                    <div className="wpb-content-wrapper">
                                      <div className="vc_row wpb_row vc_row-fluid vc_custom_1519891401815">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                          <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                              <div className="edgtf-google-map-holder">
                                                <div
                                                  className="edgtf-google-map"
                                                  style={{
                                                    minHeight: '350px',
                                                    maxHeight: '400px',
                                                  }}
                                                >
                                                  <MapComponent
                                                    lat={
                                                      organization?.location
                                                        ?.lat
                                                    }
                                                    lng={
                                                      organization?.location
                                                        ?.lng
                                                    }
                                                    city={
                                                      organization?.location_type ===
                                                      'CITY'
                                                        ? organization?.location
                                                            ?.addressString
                                                        : null
                                                    }
                                                  />
                                                  {/* <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52943468.08143988!2d-161.94000782076728!3d35.9123934915405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1731388906028!5m2!1sen!2sin"
                                                    //   width="600"
                                                    //   height="450"
                                                    style={{
                                                      border: '0',
                                                      height: '100%',
                                                    }}
                                                    allowfullscreen=""
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                  ></iframe> */}
                                                </div>
                                                <div className="edgtf-google-map-overlay"></div>
                                              </div>
                                              <div className="offbeat-event-social content-center mt-3">
                                                <div className="edgtf-social-share-holder edgtf-list">
                                                  <h4 className="edgtf-social-title d-block pb-3">
                                                    Follow and Share
                                                  </h4>
                                                  <ul>
                                                    <li className="edgtf-facebook-share">
                                                      <Link
                                                        itemProp="url"
                                                        className="edgtf-share-link"
                                                        to="#"
                                                        onClick="window.open('https://www.facebook.com/sharer.php?u=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F', 'sharer', 'toolbar=0,status=0,width=620,height=280');"
                                                      >
                                                        <span className="edgtf-social-network-icon social_facebook_circle"></span>
                                                      </Link>
                                                    </li>
                                                    <li className="edgtf-twitter-share">
                                                      <Link
                                                        itemProp="url"
                                                        className="edgtf-share-link"
                                                        to="#"
                                                        onClick="window.open('https://twitter.com/intent/tweet?text=https://offbeat.qodeinteractive.com/event/greenspan/', 'popupwindow', 'scrollbars=yes,width=800,height=400');"
                                                      >
                                                        <span className="edgtf-social-network-icon social_twitter_circle"></span>
                                                      </Link>
                                                    </li>
                                                    <li className="edgtf-linkedin-share">
                                                      <Link
                                                        itemProp="url"
                                                        className="edgtf-share-link"
                                                        to="#"
                                                        onClick="popUp=window.open('https://www.linkedin.com/sharing/share-offsite?url==https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;title=Greenspan', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                                      >
                                                        <span className="edgtf-social-network-icon social_linkedin_circle"></span>
                                                      </Link>
                                                    </li>
                                                    <li className="edgtf-tumblr-share">
                                                      <Link
                                                        itemProp="url"
                                                        className="edgtf-share-link"
                                                        to="#"
                                                        onClick="popUp=window.open('https://www.tumblr.com/share/link?url=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;name=Greenspan&amp;description=', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                                      >
                                                        <span className="edgtf-social-network-icon social_tumblr_circle"></span>
                                                      </Link>
                                                    </li>{' '}
                                                  </ul>
                                                </div>{' '}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!isLoading && eventList?.length > 0 && (
                              <div className="offbeat-event-top-left edgtf-grid-col-12 px-md-2 pb-5">
                                <div className="vc_row wpb_row vc_row-fluid">
                                  <div className="wpb_column vc_column_container vc_col-sm-12">
                                    <div className="vc_column-inner">
                                      <div className="wpb_wrapper">
                                        {/* Events Listing START */}
                                        <div className="events-listing py-0 px-md-2">
                                          <var className="edgtf-st-tagline">
                                            Upcoming Events{' '}
                                          </var>
                                          <div className="outer outer-new">
                                            {!isLoading &&
                                              eventList?.map((item, index) => (
                                                <EventCard
                                                  event={item}
                                                  imageUser={
                                                    item?.banner?.fullUrl
                                                  }
                                                  heading={item?.name}
                                                  text={
                                                    item?.startDateTimeFormat
                                                  }
                                                  key={index}
                                                />
                                              ))}
                                          </div>
                                        </div>
                                        {/* Events Listing END */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* new html start */}

            <Footer />
          </div>
        </div>
      </div>
      <div className="promo-mode d-none">
        <h4>Preview Mode</h4>
        <p>
          There&apos;s a chance this page is not yet published. Don&apos;t share
          this link with anyone.
        </p>
      </div>
    </>
  );
};

OrgEvent.propTypes = {
  orgId: PropTypes.string,
};

export default OrgEvent;
