import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  EVENT_TICKET_ENDPOINT,
  SAVE_CART_ENDPOINT,
} from '../../../../Utils/Endpoints';
import Logger from '../../../../Utils/Logger';
import { TICKET_CLASS } from '../../../../Utils/ModelClasses';
import Toast from '../../../../Utils/Toast';
import MapComponent from './MapComponent';
import PropTypes from 'prop-types';
import { encrypt } from '../../../../Utils/common';

const EventPreview = ({ eventId }) => {
  const location = useLocation();
  const [ticketList, setTicketList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [eventObject, setEventObject] = useState(
    location.state && location.state.event
  );
  const [quantities, setQuantities] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [ownerIds, setOwnerIds] = useState({}); // New state for owner IDs
  const navigate = useNavigate();
  useEffect(() => {
    if (eventId) {
      fetchEvent();
    }
    fetchTicket();
  }, []);

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${eventId}`
      );
      if (response.data.code === 200) {
        setEventObject(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTicket = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${eventObject.id}/tickets`
      );
      if (response.data.code === 200) {
        setTicketList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIncrement = (index, ticketId) => {
    setQuantities((prev) => ({
      ...prev,
      [index]: (prev[index] || 0) + 1,
    }));
    // Save the ticket ID as owner_id
    setOwnerIds((prev) => ({
      ...prev,
      [index]: ticketId,
    }));
  };

  const handleDecrement = (index) => {
    setQuantities((prev) => {
      const newQuantity = (prev[index] || 0) - 1;
      if (newQuantity < 0) return prev;
      return {
        ...prev,
        [index]: newQuantity,
      };
    });
  };

  const createTicketArray = () => {
    const ticketArray = [];

    Object.keys(quantities).forEach((index) => {
      const quantity = quantities[index];
      const ownerId = ownerIds[index];

      if (quantity > 0 && ownerId) {
        // Only include if quantity is greater than 0
        ticketArray.push({
          owner_class: TICKET_CLASS,
          owner_id: ownerId,
          quantity: quantity,
        });
      }
    });

    return ticketArray;
  };

  const handleSaveToCart = async (e) => {
    e.preventDefault();
    setIsCartLoading(true);
    try {
      let requestObject = {
        items: createTicketArray(),
      };

      const response = await axiosInstance.post(
        SAVE_CART_ENDPOINT,
        requestObject
      );
      if (response.data.code === 200) {
        navigate('/cart');
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsCartLoading(false);
    }
  };

  const isButtonDisabled = createTicketArray().length === 0 || isCartLoading;

  return (
    <>
      <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            {/* new html start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div
                  className="edgtf-title-holder edgtf-standard-type edgtf-title-va-header-bottom"
                  style={{ height: '150px', backgroundColor: '#000000' }}
                  data-height="150"
                >
                  <div
                    className="edgtf-title-wrapper"
                    style={{ height: '150px' }}
                  >
                    <div className="edgtf-title-inner">
                      <div className="edgtf-grid">
                        <p
                          className="edgtf-page-subtitle"
                          style={{ color: '#f45f0b' }}
                        >
                          {eventObject?.organization?.name}
                        </p>
                        <h2 className="edgtf-page-title entry-title">
                          {eventObject?.name}
                          <span
                            className="edgtf-page-title-suffix"
                            style={{ color: '#f45f0b' }}
                          >
                            .
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="offbeat-event-single-holder">
                  <div className="edgtf-full-width">
                    <div className="edgtf-full-width-inner pt-0">
                      <div className="edgtf-grid p-l-r">
                        <div className="offbeat-event-top-holder edgtf-grid-row edgtf-grid-huge-gutter preview-tt">
                          <div className="offbeat-event-top-right edgtf-grid-col-4 off-beat px-md-2">
                            <div className="offbeat-event-featured-image pt-1">
                              <img
                                width="800"
                                height="943"
                                src={eventObject?.banner?.fullUrl}
                                className="attachment-full size-full wp-post-image hh-img"
                                alt="efqw"
                                decoding="async"
                                fetchPriority="high"
                                // srcSet="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1.jpg 800w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1-98x116.jpg 98w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1-255x300.jpg 255w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1-768x905.jpg 768w"
                                sizes="(max-width: 800px) 100vw, 800px"
                              />
                            </div>
                            <div className="user-ticket-details">
                              <h4 className="offbeat-event-info-title">
                                Details
                              </h4>
                              <div className="offbeat-event-info-item offbeat-event-date">
                                <span className="offbeat-event-info-item-title">
                                  Start:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  {eventObject?.startDateTimeFormat}
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-date">
                                <span className="offbeat-event-info-item-title">
                                  End:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  {eventObject?.endDateTimeFormat}
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-categories">
                                <span className="offbeat-event-info-item-title">
                                  Type:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  {eventObject?.locationTypeName}
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-location d-none">
                                <span className="offbeat-event-info-item-title">
                                  Type:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  open
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-location d-none">
                                <span className="offbeat-event-info-item-title">
                                  Entrants:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  100
                                </span>
                              </div>
                            </div>
                            <div className="offbeat-event-social content-center mt-5">
                              <div className="edgtf-social-share-holder edgtf-list">
                                <h4 className="edgtf-social-title d-block pb-3">
                                  Follow and Share
                                </h4>
                                <ul>
                                  <li className="edgtf-facebook-share">
                                    <Link
                                      itemProp="url"
                                      className="edgtf-share-link"
                                      to="#"
                                      onClick="window.open('https://www.facebook.com/sharer.php?u=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F', 'sharer', 'toolbar=0,status=0,width=620,height=280');"
                                    >
                                      <span className="edgtf-social-network-icon social_facebook_circle"></span>
                                    </Link>
                                  </li>
                                  <li className="edgtf-twitter-share">
                                    <Link
                                      itemProp="url"
                                      className="edgtf-share-link"
                                      to="#"
                                      onClick="window.open('https://twitter.com/intent/tweet?text=https://offbeat.qodeinteractive.com/event/greenspan/', 'popupwindow', 'scrollbars=yes,width=800,height=400');"
                                    >
                                      <span className="edgtf-social-network-icon social_twitter_circle"></span>
                                    </Link>
                                  </li>
                                  <li className="edgtf-linkedin-share">
                                    <Link
                                      itemProp="url"
                                      className="edgtf-share-link"
                                      to="#"
                                      onClick="popUp=window.open('https://www.linkedin.com/sharing/share-offsite?url==https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;title=Greenspan', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                    >
                                      <span className="edgtf-social-network-icon social_linkedin_circle"></span>
                                    </Link>
                                  </li>
                                  <li className="edgtf-tumblr-share">
                                    <Link
                                      itemProp="url"
                                      className="edgtf-share-link"
                                      to="#"
                                      onClick="popUp=window.open('https://www.tumblr.com/share/link?url=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;name=Greenspan&amp;description=', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                    >
                                      <span className="edgtf-social-network-icon social_tumblr_circle"></span>
                                    </Link>
                                  </li>{' '}
                                </ul>
                              </div>{' '}
                            </div>
                          </div>
                          <div className="offbeat-event-top-left edgtf-grid-col-8 px-md-2">
                            <div className="offbeat-event-content-wrap clearfix">
                              <div className="offbeat-event-text-main content-center">
                                <div className="offbeat-event-content">
                                  <div className="wpb-content-wrapper">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1519891401815">
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div className="edgtf-google-map-holder">
                                              <div
                                                className="edgtf-google-map"
                                                style={{
                                                  minHeight: '350px',
                                                  maxHeight: '400px',
                                                }}
                                              >
                                                <MapComponent
                                                  lat={
                                                    eventObject?.location?.lat
                                                  }
                                                  lng={
                                                    eventObject?.location?.lng
                                                  }
                                                  city={
                                                    eventObject?.location_type ===
                                                    'CITY'
                                                      ? eventObject?.location
                                                          ?.addressString
                                                      : null
                                                  }
                                                />
                                              </div>
                                              <div className="edgtf-google-map-overlay"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_row-fluid d-none">
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div
                                              className="vc_empty_space"
                                              style={{ height: '53px' }}
                                            >
                                              <span className="vc_empty_space_inner"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="vc_row wpb_row vc_row-fluid"
                                      style={{ paddingTop: '14px' }}
                                    >
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element">
                                              <div className="wpb_wrapper">
                                                <h4>About Tour</h4>
                                              </div>
                                            </div>
                                            <div
                                              className="vc_empty_space"
                                              style={{ height: '6px' }}
                                            >
                                              <span className="vc_empty_space_inner"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_row-fluid">
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element">
                                              <div className="wpb_wrapper">
                                                <p>
                                                  {eventObject?.description}
                                                </p>
                                                <p>
                                                  Lorem ipsum dolor sit amet,
                                                  est ad graecis principes. Ad
                                                  vis iisque saperet. Eu eos
                                                  quod affert. Vim invidunt
                                                  efficiendi ea, eu eos veniam
                                                  percipit dignissim, an cum
                                                  suas laudem. Eum eu ipsum
                                                  mentitum delectus. Te vix
                                                  solet consulatu expetendis.
                                                  Dictas elige ndi antiopam has
                                                  ne, admodum hendrerit eu vis,
                                                  sit nonumy oporere eu. Ei qui
                                                  solet offendit. Ius no graeco
                                                  possim aeterno, eam at omnium
                                                  diceret accumsan. Eu nec
                                                  iisque utroque, ad qui veniam
                                                  hendrerit. Quas pertinax
                                                  vulputate cu mea, no duo
                                                  ubique.
                                                </p>
                                                <p>
                                                  FREE Admission for up to 4
                                                  people
                                                </p>
                                                <p>
                                                  express entry (no waiting in
                                                  line)
                                                </p>
                                                <p>
                                                  all gratuity and tax charges
                                                </p>
                                                <p>
                                                  a “march” to your table with
                                                  our waitresses holding your
                                                  bottles and sparklers
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="offbeat-event-social content-center d-none">
                                <div className="edgtf-social-share-holder edgtf-list">
                                  <h4 className="edgtf-social-title">
                                    Follow and Share
                                  </h4>
                                  <ul>
                                    <li className="edgtf-facebook-share">
                                      <Link
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        to="#"
                                        onClick="window.open('https://www.facebook.com/sharer.php?u=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F', 'sharer', 'toolbar=0,status=0,width=620,height=280');"
                                      >
                                        <span className="edgtf-social-network-icon social_facebook_circle"></span>
                                      </Link>
                                    </li>
                                    <li className="edgtf-twitter-share">
                                      <Link
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        to="#"
                                        onClick="window.open('https://twitter.com/intent/tweet?text=https://offbeat.qodeinteractive.com/event/greenspan/', 'popupwindow', 'scrollbars=yes,width=800,height=400');"
                                      >
                                        <span className="edgtf-social-network-icon social_twitter_circle"></span>
                                      </Link>
                                    </li>
                                    <li className="edgtf-linkedin-share">
                                      <Link
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        to="#"
                                        onClick="popUp=window.open('https://www.linkedin.com/sharing/share-offsite?url==https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;title=Greenspan', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                      >
                                        <span className="edgtf-social-network-icon social_linkedin_circle"></span>
                                      </Link>
                                    </li>
                                    <li className="edgtf-tumblr-share">
                                      <Link
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        to="#"
                                        onClick="popUp=window.open('https://www.tumblr.com/share/link?url=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;name=Greenspan&amp;description=', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                      >
                                        <span className="edgtf-social-network-icon social_tumblr_circle"></span>
                                      </Link>
                                    </li>{' '}
                                  </ul>
                                </div>{' '}
                              </div>
                            </div>
                          </div>

                          {/* ticket new create */}
                          {!isLoading && ticketList?.length > 0 && (
                            <div className="wpb_wrapper pb-5">
                              <div className="offbeat-event-list-holder offbeat-event-list-standard edgtf-light-skin edgtf-el-no-columns edgtf-normal-space">
                                <div className="offbeat-event-list-holder-inner">
                                  {ticketList?.map((item, index) => (
                                    <div
                                      className="edgtf-el-item content-center"
                                      key={index}
                                    >
                                      <Link
                                        className="edgtf-el-item-link-outer"
                                        to="#"
                                      ></Link>
                                      <div className="edgtf-el-item-date-holder">
                                        <div className="edgtf-el-item-date">
                                          <span className="edgtf-el-item-day pe-3">
                                            {item?.priceFormat}
                                          </span>
                                          <div className="edgtf-el-item-mw d-none">
                                            <span className="edgtf-el-item-month">
                                              Jun
                                            </span>
                                            <span className="edgtf-el-item-weekday">
                                              Tue
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="edgtf-el-item-title-holder">
                                        <h4 className="edgtf-el-item-title">
                                          {item?.name}
                                        </h4>
                                        <div className="ps-4 d-flex gap-2">
                                          <small>
                                            Start Date:
                                            {item?.sellingStartDateTimeFormat}
                                          </small>
                                          <small>
                                            End Date:
                                            {item?.sellingEndDateTimeFormat}
                                          </small>
                                        </div>
                                      </div>
                                      {item?.status === 'AVAILABLE' && (
                                        <div className="edgtf-el-item-link-holder buy-now">
                                          <button
                                            onClick={() =>
                                              handleDecrement(index)
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            type="text"
                                            value={quantities[index] || 0}
                                            className="text-white"
                                            readOnly
                                          />
                                          <button
                                            onClick={() =>
                                              handleIncrement(index, item?.id)
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      )}

                                      {item?.status !== 'AVAILABLE' && (
                                        <div className="edgtf-el-item-link-holder">
                                          <span className="edgtf-sold-out text-danger">
                                            {item?.status}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                  <div className="edgtf-el-item content-center d-none">
                                    <Link
                                      className="edgtf-el-item-link-outer"
                                      to="#"
                                    ></Link>
                                    <div className="edgtf-el-item-date-holder">
                                      <div className="edgtf-el-item-date">
                                        <span className="edgtf-el-item-day pe-3">
                                          $3.00
                                        </span>
                                        <div className="edgtf-el-item-mw d-none">
                                          <span className="edgtf-el-item-month">
                                            Jun
                                          </span>
                                          <span className="edgtf-el-item-weekday">
                                            Tue
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="edgtf-el-item-title-holder">
                                      <h4 className="edgtf-el-item-title">
                                        Collective{' '}
                                      </h4>
                                      <div className="ps-4 d-flex gap-2">
                                        <small>Start Date:27-10-24</small>
                                        <small>End Date:27-10-24</small>
                                      </div>
                                    </div>

                                    <div className="edgtf-el-item-link-holder">
                                      <span className="edgtf-sold-out text-danger">
                                        Sold Out
                                      </span>
                                    </div>
                                  </div>
                                  <div className="edgtf-el-item content-center d-none">
                                    <Link
                                      className="edgtf-el-item-link-outer"
                                      to="#"
                                    ></Link>
                                    <div className="edgtf-el-item-date-holder">
                                      <div className="edgtf-el-item-date">
                                        <span className="edgtf-el-item-day pe-3">
                                          $4.00
                                        </span>
                                        <div className="edgtf-el-item-mw d-none">
                                          <span className="edgtf-el-item-month">
                                            Jun
                                          </span>
                                          <span className="edgtf-el-item-weekday">
                                            Thu
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="edgtf-el-item-title-holder">
                                      <h4 className="edgtf-el-item-title">
                                        Nadaville
                                      </h4>
                                      <div className="ps-4 d-flex gap-2">
                                        <small>Start Date:27-10-24</small>
                                        <small>End Date:27-10-24</small>
                                      </div>
                                    </div>

                                    <div className="edgtf-el-item-link-holder buy-now">
                                      <button>-</button>
                                      <input type="text" placeholder="1" />
                                      <button>+</button>
                                    </div>
                                  </div>
                                  <div className="check-now-btn">
                                    <button
                                      itemProp="url"
                                      disabled={isButtonDisabled}
                                      type="button"
                                      onClick={handleSaveToCart}
                                      target="_self"
                                      className="edgtf-btn   edgtf-btn-large edgtf-btn-solid edgtf-btn-orange-white edgtf-el-item-link"
                                    >
                                      <span className="edgtf-btn-text">
                                        {isCartLoading
                                          ? 'Loading.....'
                                          : 'Save to Cart'}
                                      </span>
                                    </button>
                                  </div>
                                  {generalError && (
                                    <div className="error">{generalError}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {/* ticket new create */}
                        </div>
                        {eventObject?.organization && (
                          <div className="org-event">
                            <h5 className="pb-1">Organizer</h5>
                            <Link
                              to={`/${encrypt('ORG', eventObject?.organization?.id)}`}
                              className="add-org-ev"
                            >
                              <img
                                src={
                                  eventObject?.organization?.banner?.fullUrl
                                    ? eventObject?.organization?.banner?.fullUrl
                                    : '../images/thumbnail.png'
                                }
                                alt={eventObject?.organization?.name}
                              />
                              <p>{eventObject?.organization?.name}</p>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="edgtf-content-bottom d-none"
                  style={{ backgroundColor: '#000000' }}
                >
                  <div className="edgtf-content-bottom-inner edgtf-full-width">
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-full-width">
                        <div
                          className="edgtf-separator"
                          style={{
                            borderStyle: 'solid',
                            width: '100%',
                            borderBottomWidth: '1px',
                            marginTop: '0px',
                            marginBottom: '130px',
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="widget widget_media_image">
                      <Link to="https://offbeat.qodeinteractive.com/">
                        <img
                          width="424"
                          height="87"
                          src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom.png"
                          className="image wp-image-4366  attachment-full size-full"
                          alt="f"
                          style={{ maxWidth: '100%', height: 'auto' }}
                          decoding="async"
                          loading="lazy"
                          srcSet="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom.png 424w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom-150x31.png 150w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom-300x62.png 300w"
                          sizes="(max-width: 424px) 100vw, 424px"
                        />
                      </Link>
                    </div>
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                        <div
                          className="edgtf-separator"
                          style={{ borderStyle: 'solid', marginBottom: '0px' }}
                        ></div>
                      </div>
                    </div>
                    <div className="widget widget_nav_menu">
                      <div className="menu-footer-menu-container">
                        <ul id="menu-footer-menu" className="menu">
                          <li
                            id="menu-item-1002"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-1002"
                          >
                            <Link to="https://offbeat.qodeinteractive.com/">
                              Home
                            </Link>
                          </li>
                          <li
                            id="menu-item-3428"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3428"
                          >
                            <Link to="https://offbeat.qodeinteractive.com/reservation-form/">
                              Reservation Form
                            </Link>
                          </li>
                          <li
                            id="menu-item-1005"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1005"
                          >
                            <Link to="https://offbeat.qodeinteractive.com/about-me/">
                              About
                            </Link>
                          </li>
                          <li
                            id="menu-item-1006"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1006"
                          >
                            <Link to="https://offbeat.qodeinteractive.com/menu/">
                              Menu
                            </Link>
                          </li>
                          <li
                            id="menu-item-3430"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3430"
                          >
                            <Link to="https://offbeat.qodeinteractive.com/blog/standard-right-sidebar/">
                              Blog
                            </Link>
                          </li>
                          <li
                            id="menu-item-3429"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3429"
                          >
                            <Link to="https://offbeat.qodeinteractive.com/contact-us/">
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                        <div
                          className="edgtf-separator"
                          style={{ borderStyle: 'solid', marginBottom: '2px' }}
                        ></div>
                      </div>
                    </div>
                    <div className="widget edgtf-social-icons-group-widget text-align-center">
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://www.instagram.com/qodeinteractive/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://www.facebook.com/QodeInteractive/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://plus.google.com/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://twitter.com/QodeInteractive"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://www.youtube.com/QodeInteractiveVideos"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://vimeo.com/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                      </Link>
                    </div>
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                        <div
                          className="edgtf-separator"
                          style={{
                            borderStyle: 'solid',
                            marginBottom: '115px',
                          }}
                        ></div>
                      </div>
                    </div>{' '}
                  </div>
                </div>
              </div>
            </div>
            {/* new html start */}

            <Footer />
          </div>
        </div>
      </div>
      <div className="promo-mode d-none">
        <h4>Preview Mode</h4>
        <p>
          There&apos;s a chance this page is not yet published. Don&apos;t share
          this link with anyone.
        </p>
      </div>
    </>
  );
};

EventPreview.propTypes = {
  eventId: PropTypes.string,
};

export default EventPreview;
