import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import axiosInstance from '../../../../../Utils/axiosInstance';
import {
  CHECKOUT_PAYMENT_CONFIRM_ENDPOINT,
  CHECKOUT_PAYMENT_INIT_ENDPOINT,
} from '../../../../../Utils/Endpoints';
import { clearItem, getObject } from '../../../../../Utils/LocalStorage';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ stripeData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      let requestObject = {
        payment_method_id: stripeData?.id,
        notes: null,
      };
      const orderData = getObject('proceedCheckoutData');

      const response = await axiosInstance.post(
        CHECKOUT_PAYMENT_INIT_ENDPOINT + orderData?.id,
        requestObject
      );

      console.log('Response from payment initiation:', response.data);

      if (response.data.code === 200) {
        const { gateway_response, id } = response.data.data;
        const { error: stripeError, paymentIntent } =
          await stripe.confirmCardPayment(gateway_response.client_secret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          });

        if (stripeError) {
          console.error('Stripe error:', stripeError);
          setError(stripeError.message);
        } else {
          console.log('Payment successful:', paymentIntent);
          const responseConfirm = await axiosInstance.post(
            CHECKOUT_PAYMENT_CONFIRM_ENDPOINT + id,
            {}
          );
          if (responseConfirm.data.code === 200) {
            console.log('Payment confirmed successfully', responseConfirm);
            navigate('/thank-you-check-out', {
              state: {
                order: responseConfirm.data.data,
              },
            });
            clearItem('proceedCheckoutData');
          } else {
            setError(responseConfirm.data.message);
          }
        }
      } else {
        console.error('Error from payment initiation:', response.data.message);
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Catch block error:', error);
      setError(error.response?.data?.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Card Number</label>
      <CardNumberElement className="form-control payment-card" />
      <label>Expiry Date</label>
      <CardExpiryElement className="form-control payment-card" />
      <label>CVC</label>
      <CardCvcElement className="form-control payment-card" />
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className="button alt"
      >
        {isProcessing ? 'Loading...' : 'Place order'}
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

const StripeCheckout = ({ stripeData }) => {
  const key = stripeData?.details?.test_mode
    ? stripeData?.details?.test_public_key
    : stripeData?.details?.public_key;
  const stripePromise = loadStripe(key);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm stripeData={stripeData} />
    </Elements>
  );
};

StripeCheckout.propTypes = {
  stripeData: PropTypes.object,
};
CheckoutForm.propTypes = {
  stripeData: PropTypes.object,
};

export default StripeCheckout;
