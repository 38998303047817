import { useEffect, useRef, useState } from 'react';
// import './ProfileUpdate.css';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  REMOVE_ENDPOINT,
  UPDATE_PROFILE_ENDPOINT,
} from '../../../../Utils/Endpoints';
import {
  getLoginCustomer,
  setLoginCustomer,
} from '../../../../Utils/LocalStorage';
import Logger from '../../../../Utils/Logger';
import { uploadFile } from '../../../../Utils/upload';
import { USER_PROFILE_PICTURE_TYPE } from '../../../../Utils/UploadType';
import { USER_CLASS } from '../../../../Utils/ModelClasses';
import Toast from '../../../../Utils/Toast';
import Header from '../../Home/Components/Header';
import Footer from '../../Home/Components/Footer';
// Validation schema
const ProfileUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  bio: Yup.string().required('About is required.'),
  lastName: Yup.string().required('Last Name is required'),
  password: Yup.string().test('isChangePassword', 'Required', function (value) {
    if (this.parent.isChangePassword) {
      if (value === '') {
        return this.createError({ message: 'Password is required.' });
      }
      return true;
    }
    return true;
  }),
  confirmPassword: Yup.string().test(
    'isChangePassword',
    'Required',
    function (value) {
      if (this.parent.isChangePassword) {
        if (value === '') {
          return this.createError({ message: 'Confirm Password is required.' });
        }
        if (value !== this.parent.password) {
          return this.createError({ message: 'Passwords must match' });
        }
        return true;
      }
      return true;
    }
  ),
});

const ProfileUpdate = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [dob, setDob] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const customer = getLoginCustomer();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Define allowed file types
      const allowedFileTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
      ];

      // Check if the selected file type is allowed
      if (!allowedFileTypes.includes(selectedFile.type)) {
        Toast.error(
          'Invalid file type selected. Please upload a valid image file.',
          {
            autoClose: 2000,
          }
        );
        return;
      }

      setIsImageLoading(true);
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      try {
        const imageData = await uploadFile(
          USER_CLASS,
          customer?.id,
          USER_PROFILE_PICTURE_TYPE,
          selectedFile
        );
        setPreview(imageData?.data?.data?.fullUrl);
        Logger.log(imageData);
      } catch (error) {
        Logger.error('Error uploading file:', error);
      } finally {
        setIsImageLoading(false);
      }
    }
  };

  const validate = () => {
    try {
      setGeneralError('');
      ProfileUpdateSchema.validateSync(
        {
          firstName,
          lastName,
          password,
          confirmPassword,
          isChangePassword,
          bio,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      let requestObject = {};
      if (isChangePassword) {
        requestObject = {
          first_name: firstName,
          last_name: lastName,
          password: password,
          password_confirmation: confirmPassword,
          username: userName,
          phone_number: phoneNumber,
          dob: dob,
          gender: gender,
          bio: bio ? bio : '',
        };
      } else {
        requestObject = {
          first_name: firstName,
          last_name: lastName,
          username: userName,
          phone_number: phoneNumber,
          dob: dob,
          gender: gender,
          bio: bio ? bio : '',
        };
      }
      const response = await axiosInstance.patch(
        UPDATE_PROFILE_ENDPOINT,
        requestObject
      );
      if (response.data.code === 200) {
        await setLoginCustomer(response.data.data);
        Toast.success(response.data.message, {
          onClose: () => {
            navigate('/');
          },
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customer && customer?.first_name) {
      setFirstName(customer.first_name);
      setLastName(customer.last_name);
      setUserName(customer.username);
      setGender(customer.gender);
      setBio(customer.bio);
      setDob(customer.dob);
      setEmail(customer.email);
      setPhoneNumber(customer.phone_number);
      setPreview(customer.profile_picture?.fullUrl);
    } else {
      setEmail(customer.email);
      setUserName(customer.username);
      setGender(customer.gender);
      setIsChangePassword(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClosePassword = () => {
    setIsChangePassword(!isChangePassword);
    setConfirmPassword('');
    setPassword('');
    setShowConfirmPassword(false);
    setShowPassword(false);
    setErrors({});
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      setIsImageLoading(true);
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);
      if (response.status === 204) {
        setPreview(null);
        setFile(null);
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsImageLoading(false);
    }
  };

  return (
    <>
      <div className="log-in d-none">
        <img className="image" src="/images/background.png" alt="Image" />
        <div className="left">
          <div className="logo">
            <img className="img" alt="Logo" src="/images/logo.png" />
          </div>
        </div>
        <div className="right">
          <div className="form-outer">
            <h2 className="main-heading">Update Profile </h2>
            <p className="p">Please fill out your details</p>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form">
                <input
                  className="input input-instance"
                  type="text"
                  name="firstName"
                  value={firstName}
                  placeholder="First Name*"
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="off"
                />
                {errors.firstName && (
                  <div className="error">{errors.firstName}</div>
                )}
                <input
                  className="input input-instance"
                  type="text"
                  name="lastName"
                  value={lastName}
                  placeholder="Last Name*"
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="off"
                />
                {errors.lastName && (
                  <div className="error">{errors.lastName}</div>
                )}
                <input
                  className="input input-instance"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password*"
                  autoComplete="off"
                />
                {errors.password && (
                  <div className="error">{errors.password}</div>
                )}
                <input
                  className="input input-instance"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password*"
                  autoComplete="off"
                />
                {errors.confirmPassword && (
                  <div className="error">{errors.confirmPassword}</div>
                )}
                <div className="spacer" />
                {generalError && <div className="error">{generalError}</div>}
                <button
                  className={`button button-instance`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      className="spinner"
                    >
                      <circle
                        className="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        strokeWidth="5"
                      />
                    </svg>
                  ) : (
                    <div className="continue">Submit</div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="page-breadcrumb d-flex align-items-center mb-3 p-4 d-none">
        <div className="font-22">Edit Profile</div>
      </div>
      <div className="row d-none">
        <div className="col-lg-8 mx-auto">
          <div className="card radius-10">
            <div className="card-body">
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="mb-4 d-flex flex-column gap-3 align-items-center justify-content-center">
                  <div
                    className={`user-change-photo shadow ${isImageLoading ? ' over-flow ' : ''} `}
                  >
                    <img
                      src={preview ? preview : '../images/profile.png'}
                      alt="..."
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm radius-30 px-4"
                    onClick={handleButtonClick}
                    disabled={isImageLoading}
                  >
                    <ion-icon name="image-sharp"></ion-icon>Change Photo
                  </button>
                  {customer?.photo && (
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm radius-30 px-4"
                    >
                      <ion-icon name="trash-outline"></ion-icon>Remove Photo
                    </button>
                  )}

                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                </div>
                <h5 className="mb-0 mt-4">User Information</h5>
                <hr />
                <div className="row g-3">
                  <div className="col-6">
                    <label className="form-label">UserName</label>
                    <input
                      type="text"
                      className="form-control"
                      name="userName"
                      value={userName}
                      placeholder="User Name*"
                      onChange={(e) => setUserName(e.target.value)}
                      autoComplete="off"
                    />
                    {errors.userName && (
                      <div className="error">{errors.userName}</div>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="form-label">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      disabled
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={firstName}
                      placeholder="First Name*"
                      onChange={(e) => setFirstName(e.target.value)}
                      autoComplete="off"
                    />
                    {errors.firstName && (
                      <div className="error">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={lastName}
                      placeholder="Last Name*"
                      onChange={(e) => setLastName(e.target.value)}
                      autoComplete="off"
                    />
                    {errors.lastName && (
                      <div className="error">{errors.lastName}</div>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="form-label">DOB</label>
                    <input
                      type="date"
                      name="dob"
                      className="form-control"
                      onChange={(e) => setDob(e.target.value)}
                      value={dob}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Gender</label>
                    <select
                      className="form-select mb-0"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </select>
                  </div>

                  <div className="col-12">
                    <label className="form-label">About Me</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      cols="4"
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                      placeholder="Describe yourself..."
                    ></textarea>
                    {errors.bio && <div className="error">{errors.bio}</div>}
                  </div>

                  {!isChangePassword && (
                    <div className="col-lg-3">
                      <div className="tickets">
                        <span
                          onClick={() => setIsChangePassword(!isChangePassword)}
                          className="c-pass-word text-start pb-3 cp"
                        >
                          Change password
                        </span>
                      </div>
                    </div>
                  )}
                  {isChangePassword && (
                    <>
                      <div className="col-6">
                        <label className="form-label">Password</label>
                        <div className="input-pass-word">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password*"
                            autoComplete="off"
                          />
                          <span onClick={togglePasswordVisibility}>
                            {showPassword ? (
                              <img
                                src="../images/eye-password-hide.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="../images/eye-password-show.svg"
                                alt=""
                              />
                            )}
                          </span>
                        </div>
                        {errors.password && (
                          <div className="error">{errors.password}</div>
                        )}
                      </div>
                      <div className="col-6">
                        <label className="form-label">Confirm Password</label>
                        <div className="input-pass-word">
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            className="form-control"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password*"
                            autoComplete="off"
                          />
                          <span onClick={toggleConfirmPasswordVisibility}>
                            {showConfirmPassword ? (
                              <img
                                src="../images/eye-password-hide.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="../images/eye-password-show.svg"
                                alt=""
                              />
                            )}
                          </span>
                        </div>
                        {errors.confirmPassword && (
                          <div className="error">{errors.confirmPassword}</div>
                        )}
                        {customer?.first_name && (
                          <span
                            onClick={handleClosePassword}
                            className="c-pass-word cp text-end d-block pt-2"
                          >
                            Close
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <h5 className="mb-0 mt-4 d-none">Contact Information</h5>
                <hr />
                <div className="row g-3 d-none">
                  <div className="col-12">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value="47-A, city name, United States"
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">City</label>
                    <input type="text" className="form-control" value="@jhon" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      className="form-control"
                      value="xyz@example.com"
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Pin Code</label>
                    <input type="text" className="form-control" value="jhon" />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label">About Me</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      cols="4"
                      placeholder="Describe yourself..."
                    ></textarea>
                  </div>
                </div>
                {generalError && <div className="error">{generalError}</div>}
                <div className="text-start mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary px-4"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="edgtf-header-standard  edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            {/* new html start */}
            <div className="edgtf-content d-none">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>

                          <h2 className="text-white">Edit Profile</h2>
                        </div>
                        <div className="page-breadcrumb d-flex align-items-center mb-3 p-4 d-none">
                          <div className="font-22">Edit Profile</div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 mx-auto">
                            <div className="card radius-10">
                              <div className="card-body input-all-color">
                                <form
                                  className="login-form"
                                  onSubmit={handleSubmit}
                                >
                                  <div className="mb-4 d-flex flex-column gap-3 align-items-center justify-content-center">
                                    <div
                                      className={`user-change-photo shadow ${isImageLoading ? ' over-flow ' : ''} `}
                                    >
                                      <img
                                        src={
                                          preview
                                            ? preview
                                            : '../images/profile.png'
                                        }
                                        alt="..."
                                      />
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary btn-sm radius-30 px-4"
                                      onClick={handleButtonClick}
                                      disabled={isImageLoading}
                                    >
                                      <ion-icon name="image-sharp"></ion-icon>
                                      Change Photo
                                    </button>
                                    {customer?.photo && (
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger btn-sm radius-30 px-4"
                                      >
                                        <ion-icon name="trash-outline"></ion-icon>
                                        Remove Photo
                                      </button>
                                    )}

                                    <input
                                      type="file"
                                      id="fileInput"
                                      onChange={handleFileChange}
                                      ref={fileInputRef}
                                      style={{ display: 'none' }}
                                    />
                                  </div>
                                  <h5 className="mb-0 mt-4">
                                    User Information
                                  </h5>
                                  <hr />
                                  <div className="row g-3">
                                    <div className="col-md-6">
                                      <label className="form-label">
                                        UserName
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="userName"
                                        value={userName}
                                        placeholder="User Name*"
                                        onChange={(e) =>
                                          setUserName(e.target.value)
                                        }
                                        autoComplete="off"
                                      />
                                      {errors.userName && (
                                        <div className="error">
                                          {errors.userName}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-label">
                                        Email Address
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        value={email}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-label">
                                        First Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        value={firstName}
                                        placeholder="First Name*"
                                        onChange={(e) =>
                                          setFirstName(e.target.value)
                                        }
                                        autoComplete="off"
                                      />
                                      {errors.firstName && (
                                        <div className="error">
                                          {errors.firstName}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-label">
                                        Last Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        value={lastName}
                                        placeholder="Last Name*"
                                        onChange={(e) =>
                                          setLastName(e.target.value)
                                        }
                                        autoComplete="off"
                                      />
                                      {errors.lastName && (
                                        <div className="error">
                                          {errors.lastName}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-label">DOB</label>
                                      <input
                                        type="date"
                                        name="dob"
                                        className="form-control"
                                        onChange={(e) => setDob(e.target.value)}
                                        value={dob}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-label">
                                        Gender
                                      </label>
                                      <select
                                        className="form-select mb-0"
                                        value={gender}
                                        onChange={(e) =>
                                          setGender(e.target.value)
                                        }
                                      >
                                        <option value="">Select Gender</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                        <option value="OTHER">Other</option>
                                      </select>
                                    </div>

                                    <div className="col-12">
                                      <label className="form-label">
                                        About Me
                                      </label>
                                      <textarea
                                        className="form-control"
                                        rows="4"
                                        cols="4"
                                        value={bio}
                                        onChange={(e) => setBio(e.target.value)}
                                        placeholder="Describe yourself..."
                                      ></textarea>
                                      {errors.bio && (
                                        <div className="error">
                                          {errors.bio}
                                        </div>
                                      )}
                                    </div>

                                    {!isChangePassword && (
                                      <div className="col-lg-3">
                                        <div className="tickets">
                                          <span
                                            onClick={() =>
                                              setIsChangePassword(
                                                !isChangePassword
                                              )
                                            }
                                            className="c-pass-word text-start pb-3 cp"
                                          >
                                            Change password
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {isChangePassword && (
                                      <>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Password
                                          </label>
                                          <div className="input-pass-word">
                                            <input
                                              type={
                                                showPassword
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              className="form-control"
                                              name="password"
                                              value={password}
                                              onChange={(e) =>
                                                setPassword(e.target.value)
                                              }
                                              placeholder="Password*"
                                              autoComplete="off"
                                            />
                                            <span
                                              onClick={togglePasswordVisibility}
                                            >
                                              {showPassword ? (
                                                <img
                                                  src="../images/eye-password-hide.svg"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src="../images/eye-password-show.svg"
                                                  alt=""
                                                />
                                              )}
                                            </span>
                                          </div>
                                          {errors.password && (
                                            <div className="error">
                                              {errors.password}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Confirm Password
                                          </label>
                                          <div className="input-pass-word">
                                            <input
                                              type={
                                                showConfirmPassword
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              className="form-control"
                                              name="confirmPassword"
                                              value={confirmPassword}
                                              onChange={(e) =>
                                                setConfirmPassword(
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Confirm Password*"
                                              autoComplete="off"
                                            />
                                            <span
                                              onClick={
                                                toggleConfirmPasswordVisibility
                                              }
                                            >
                                              {showConfirmPassword ? (
                                                <img
                                                  src="../images/eye-password-hide.svg"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src="../images/eye-password-show.svg"
                                                  alt=""
                                                />
                                              )}
                                            </span>
                                          </div>
                                          {errors.confirmPassword && (
                                            <div className="error">
                                              {errors.confirmPassword}
                                            </div>
                                          )}
                                          {customer?.first_name && (
                                            <span
                                              onClick={handleClosePassword}
                                              className="c-pass-word cp text-end d-block pt-2"
                                            >
                                              Close
                                            </span>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <hr />
                                  {generalError && (
                                    <div className="error">{generalError}</div>
                                  )}
                                  <div className="text-start mt-3">
                                    <button
                                      type="submit"
                                      className="btn btn-primary px-4"
                                      disabled={isLoading}
                                    >
                                      {isLoading
                                        ? 'Loading...'
                                        : 'Save Changes'}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new html start */}

            {/* new chat start */}
            <div className="edgtf-content" style={{ marginTop: '-98px' }}>
              <div className="edgtf-content-inner">
                <div
                  className="edgtf-title-holder d-none edgtf-centered-type edgtf-title-va-window-top edgtf-has-bg-image edgtf-bg-parallax"
                  style={{
                    height: '410px',
                    backgroundColor: 'rgb(0, 0, 0)',
                    backgroundImage: `url("https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/p-title-img-1-1.jpg")`,
                    backgroundPosition: 'center -57.4px',
                  }}
                  data-height="410"
                >
                  <div className="edgtf-title-image">
                    <img
                      itemProp="image"
                      src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/p-title-img-1-1.jpg"
                      alt="a"
                    />
                  </div>
                  <div className="edgtf-title-wrapper">
                    <div className="edgtf-title-inner">
                      <div className="edgtf-grid">
                        <h1 className="edgtf-page-title entry-title">
                          Reservation Form
                          <span
                            className="edgtf-page-title-suffix"
                            style={{ color: '#f45f0b' }}
                          >
                            .
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix all-pt">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="wpb-content-wrapper">
                          <div className="edgtf-row-grid-section-wrapper">
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1634043061572">
                                <div className="wpb_column vc_column_container vc_col-sm-11 vc_col-lg-offset-0 vc_col-lg-6 vc_col-md-offset-0 vc_col-md-9 vc_col-sm-offset-0">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="edgtf-section-title-holder">
                                        <div className="edgtf-st-inner">
                                          <var
                                            className="edgtf-st-tagline"
                                            style={{ marginBottom: '-7px' }}
                                          >
                                            Your very own
                                          </var>
                                          <h3 className="edgtf-st-title">
                                            Edit Profile
                                            <span className="edgtf-st-title-dot">
                                              .
                                            </span>
                                          </h3>
                                          <p
                                            className="edgtf-st-text"
                                            style={{ marginTop: '25px' }}
                                          >
                                            Alienum phaedrum torquatos nec eu,
                                            vis detraxit periculis ex,
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-1 vc_col-lg-offset-0 vc_col-lg-6 vc_col-md-offset-0 vc_col-md-3 vc_col-sm-offset-0">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="edgtf-row-grid-section-wrapper">
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1522241891078 vc_row-o-content-top vc_row-flex">
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-8 vc_col-md-offset-0 vc_col-md-12 vc_col-sm-offset-0">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div
                                        className="wpcf7 js"
                                        id="wpcf7-f183-p1492-o1"
                                        lang="en-US"
                                        dir="ltr"
                                      >
                                        <div className="screen-reader-response">
                                          <p
                                            role="status"
                                            aria-live="polite"
                                            aria-atomic="true"
                                          />
                                          <ul />
                                        </div>
                                        <form
                                          action="/reservation-form/#wpcf7-f183-p1492-o1"
                                          method="post"
                                          className="wpcf7-form demo init"
                                          aria-label="Contact form"
                                          noValidate
                                          data-status="init"
                                          onSubmit={handleSubmit}
                                        >
                                          <div>
                                            {/* error start */}
                                            <div className="">
                                              {errors.userName && (
                                                <div className="error">
                                                  {errors.userName}
                                                </div>
                                              )}
                                              {errors.firstName && (
                                                <span className="error">
                                                  {errors.firstName}
                                                </span>
                                              )}
                                              {errors.lastName && (
                                                <div className="error">
                                                  {errors.lastName}
                                                </div>
                                              )}
                                              {errors.bio && (
                                                <div className="error">
                                                  {errors.bio}
                                                </div>
                                              )}
                                              {errors.password && (
                                                <div className="error">
                                                  {errors.password}
                                                </div>
                                              )}
                                            </div>

                                            {/* error end */}
                                            {/* Form Inputs */}
                                            <div className="edgtf-grid-row edgtf-grid-small-gutter cc-u">
                                              <div className="edgtf-grid-col-6">
                                                <p>
                                                  <input
                                                    className="wpcf7-form-control wpcf7-text"
                                                    aria-invalid="false"
                                                    type="text"
                                                    name="text"
                                                    value={userName}
                                                    placeholder="User Name*"
                                                    onChange={(e) =>
                                                      setUserName(
                                                        e.target.value
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p>
                                                  <input
                                                    className="wpcf7-form-control wpcf7-text"
                                                    aria-invalid="false"
                                                    type="text"
                                                    name="text"
                                                    value={email}
                                                    disabled
                                                    placeholder="Email*"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p
                                                  style={{
                                                    position: 'relative',
                                                  }}
                                                >
                                                  <input
                                                    className="wpcf7-form-control wpcf7-text"
                                                    aria-invalid="false"
                                                    type="text"
                                                    name="firstName"
                                                    value={firstName}
                                                    placeholder="First Name*"
                                                    onChange={(e) =>
                                                      setFirstName(
                                                        e.target.value
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p>
                                                  <input
                                                    className="wpcf7-form-control wpcf7-text"
                                                    aria-invalid="false"
                                                    type="text"
                                                    placeholder="Last Name*"
                                                    name="lastName"
                                                    value={lastName}
                                                    onChange={(e) =>
                                                      setLastName(
                                                        e.target.value
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="date"
                                                  >
                                                    <input
                                                      placeholder="Date*"
                                                      type="date"
                                                      name="date"
                                                      className="wpcf7-form-control wpcf7-date wpcf7-validates-as-date"
                                                      onChange={(e) =>
                                                        setDob(e.target.value)
                                                      }
                                                      value={dob}
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="date"
                                                  >
                                                    <select
                                                      className="form-select mb-0 gender-select"
                                                      value={gender}
                                                      onChange={(e) =>
                                                        setGender(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="">
                                                        Select Gender
                                                      </option>
                                                      <option value="MALE">
                                                        Male
                                                      </option>
                                                      <option value="FEMALE">
                                                        Female
                                                      </option>
                                                      <option value="OTHER">
                                                        Other
                                                      </option>
                                                    </select>
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-12">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="textarea-984"
                                                  >
                                                    <textarea
                                                      className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                      rows="4"
                                                      cols="4"
                                                      value={bio}
                                                      onChange={(e) =>
                                                        setBio(e.target.value)
                                                      }
                                                      placeholder="Describe yourself..."
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                              {!isChangePassword && (
                                                <div className="">
                                                  <div className="tickets">
                                                    <span
                                                      onClick={() =>
                                                        setIsChangePassword(
                                                          !isChangePassword
                                                        )
                                                      }
                                                      className="c-pass-word text-start pb-3 cp"
                                                    >
                                                      Change password
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                              {isChangePassword && (
                                                <>
                                                  <div className="edgtf-grid-col-6">
                                                    <p className="input-pass-word icon-eye">
                                                      <input
                                                        type={
                                                          showPassword
                                                            ? 'text'
                                                            : 'password'
                                                        }
                                                        className="wpcf7-form-control wpcf7-tel wpcf7-text wpcf7-validates-as-tel"
                                                        name="password"
                                                        value={password}
                                                        onChange={(e) =>
                                                          setPassword(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Password*"
                                                        autoComplete="off"
                                                      />
                                                      <span
                                                        onClick={
                                                          togglePasswordVisibility
                                                        }
                                                      >
                                                        {showPassword ? (
                                                          <img
                                                            src="../images/eye-password-hide.svg"
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <img
                                                            src="../images/eye-password-show.svg"
                                                            alt=""
                                                          />
                                                        )}
                                                      </span>
                                                    </p>
                                                  </div>

                                                  <div className="edgtf-grid-col-6">
                                                    <p>
                                                      <div className="input-pass-word icon-eye">
                                                        <input
                                                          type={
                                                            showConfirmPassword
                                                              ? 'text'
                                                              : 'password'
                                                          }
                                                          className="wpcf7-form-control wpcf7-tel wpcf7-text wpcf7-validates-as-tel"
                                                          name="confirmPassword"
                                                          value={
                                                            confirmPassword
                                                          }
                                                          onChange={(e) =>
                                                            setConfirmPassword(
                                                              e.target.value
                                                            )
                                                          }
                                                          placeholder="Confirm Password*"
                                                          autoComplete="off"
                                                        />
                                                        <span
                                                          onClick={
                                                            toggleConfirmPasswordVisibility
                                                          }
                                                        >
                                                          {showConfirmPassword ? (
                                                            <img
                                                              src="../images/eye-password-hide.svg"
                                                              alt=""
                                                            />
                                                          ) : (
                                                            <img
                                                              src="../images/eye-password-show.svg"
                                                              alt=""
                                                            />
                                                          )}
                                                        </span>
                                                      </div>
                                                      {errors.confirmPassword && (
                                                        <div className="error">
                                                          {
                                                            errors.confirmPassword
                                                          }
                                                        </div>
                                                      )}
                                                      {customer?.first_name && (
                                                        <span
                                                          onClick={
                                                            handleClosePassword
                                                          }
                                                          className="c-pass-word cp text-end d-block pt-2"
                                                        >
                                                          Close
                                                        </span>
                                                      )}
                                                    </p>
                                                  </div>
                                                </>
                                              )}
                                              <div className="edgtf-grid-col-12">
                                                <div className="edgtf-position-right">
                                                  <div className="edgtf-position-right-inner">
                                                    {generalError && (
                                                      <div className="error">
                                                        {generalError}
                                                      </div>
                                                    )}
                                                    <div className="text-start mt-3">
                                                      <button
                                                        type="submit"
                                                        className="wpcf7-form-control wpcf7-submit has-spinner edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                                        disabled={isLoading}
                                                      >
                                                        {isLoading
                                                          ? 'Loading...'
                                                          : 'Save Changes'}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="wpcf7-response-output"
                                            aria-hidden="true"
                                          />
                                          {generalError && (
                                            <div className="error">
                                              {generalError}
                                            </div>
                                          )}
                                        </form>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '60px' }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-12">
                                  <div className="vc_column-inner vc_custom_1522241954618">
                                    <div className="wpb_wrapper">
                                      <div className="edgtf-single-image-holder">
                                        <div className="edgtf-si-inner d-none">
                                          <img
                                            fetchPriority="high"
                                            decoding="async"
                                            width="335"
                                            height="446"
                                            src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/p-beer-img-1.png"
                                            className="attachment-full size-full"
                                            alt="a"
                                            srcSet="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/p-beer-img-1.png 335w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/p-beer-img-1-87x116.png 87w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/p-beer-img-1-225x300.png 225w"
                                            sizes="(max-width: 335px) 100vw, 335px"
                                          />
                                        </div>
                                        <div className="">
                                          <div className="mb-4 user-create-image">
                                            <div
                                              className={`user-change-photo user-c-p shadow ${isImageLoading ? ' over-flow ' : ''} `}
                                            >
                                              <img
                                                src={
                                                  preview
                                                    ? preview
                                                    : '../images/profile.png'
                                                }
                                                alt="..."
                                              />
                                            </div>
                                            <div className="add-remove-img">
                                              {!customer?.profile_picture && (
                                                <button
                                                  type="button"
                                                  className="btn warn-ing btn-sm radius-0 p-2"
                                                  onClick={handleButtonClick}
                                                  disabled={isImageLoading}
                                                >
                                                  <img
                                                    src="../images/edit.png"
                                                    alt=""
                                                  />
                                                </button>
                                              )}
                                              {customer?.profile_picture && (
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger dan-ger btn-sm radius-0 p-2"
                                                  onClick={(e) =>
                                                    handleDelete(
                                                      e,
                                                      customer.profile_picture
                                                        .id
                                                    )
                                                  }
                                                  disabled={isImageLoading}
                                                >
                                                  <img
                                                    src="../images/delete.png"
                                                    alt=""
                                                  />
                                                </button>
                                              )}
                                            </div>
                                            <input
                                              type="file"
                                              id="fileInput"
                                              onChange={handleFileChange}
                                              ref={fileInputRef}
                                              style={{ display: 'none' }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '30px' }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* new chat end */}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileUpdate;
